import React, { useState, useEffect } from 'react';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { SchoolEvent } from 'models/event';
import { FilterField } from 'models/filter';
import { Tournament } from 'models/tournament';
import { FILTER_TYPE } from 'consts/table';
import { getTournamentResultsFormattedByAgeGroups, 
   getTournamentResultsFormattedByYears, 
   assignPositions
} from 'helpers/score';
import { getFilters } from 'helpers/filters';
import { isAthleticSwimmingTournamentSubtype } from 'helpers/tournament';
import { sortByFirstLetter } from 'helpers/common';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Filters from 'components/Filters';

const FILTER_FIELDS: FilterField[] = [
   {
      text: 'Sport',
      field: 'sport',
      type: FILTER_TYPE.SELECT
   }
];

interface Props {
   events: SchoolEvent[];
   tournament: Tournament;
}

const TableWrapper = styled(Box)(
   sx({
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '6px',
      overflow: 'hidden'
   })
);

const TableHeadCell = styled(TableCell)(
   sx({
      fontWeight: 'bold'
   })
);

export default function ScoresTable(props: Props) {
   const { events, tournament } = props;

   const [isShowFilters, setIsShowFilters] = useState<boolean>(false);
   const [filters, setFilters] = useState<any>({});
   const [rowsData, setRowsData] = useState<any[]>([]);

   const isAthleticSwimming = isAthleticSwimmingTournamentSubtype(tournament);
   const [isYearsChecked, setIsYearsChecked] = useState(isAthleticSwimming);

   const tableData = isYearsChecked
   ? getTournamentResultsFormattedByYears(events, tournament)
   : getTournamentResultsFormattedByAgeGroups(events, tournament);

   const { scores, sportNames } = tableData;

   const allResults = propz.get(scores, ['Overall results'], []);
   const isAllResultsExist = allResults.length > 0;
   const columnsData = isAllResultsExist ? Object.keys(allResults[0]) : [];

   const sportNamesSorted = [...sportNames].sort(sortByFirstLetter);

   const sportOptions = sportNamesSorted.map(sport => {
      return {
         text: sport,
         value: sport
      };
   });

   const options = {
      sport: sportOptions
   };

   const filterButtonIcon = isShowFilters ? <FilterAltOffIcon /> : <FilterAltIcon />;
   const filterButtonText = isShowFilters ? 'Hide Filters' : 'Show Filters';

   useEffect(() => {
      const sportFilter = propz.get(filters, ['sport'], '');
      const isSportFilterExist = sportFilter !== '';

      if (isSportFilterExist) {
         setRowsData(scores[sportFilter]);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [filters, isYearsChecked]);

   useEffect(() => {
      const filters = getFilters(FILTER_FIELDS, {});

      const defaultFilters = {
         ...filters,
         sport: sportNames[0]
      };

      setFilters(defaultFilters);

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const columns = columnsData.map((column, index) => {
      return <TableHeadCell key={`table_column_${index}`}>{column}</TableHeadCell>;
   });

   const rankedRows = assignPositions([...rowsData]); 

   const rows = rankedRows.map((row: any, index: number) => {
      const rowCells = columnsData.map((column: string) => {
         const tableCellContent = row[column];
         return <TableCell key={column}>{tableCellContent}</TableCell>;
      });

      return (
         <TableRow key={`table_row_${index}`}>
            <TableCell>{row.position}</TableCell>
            {rowCells}
         </TableRow>
      );
   });

   const onFilterChange = (event: React.FormEvent<HTMLSelectElement>, filterField: string): void => {
      const filterValue = propz.get(event, ['target', 'value']);

      const nextFilters = {
         ...filters,
         [filterField]: filterValue
      };

      setFilters(nextFilters);
   };

   const onClearFilterClick = () => {
      const filters = getFilters(FILTER_FIELDS, {});

      const defaultFilters = {
         ...filters,
         sport: sportNames[0]
      };

      setFilters(defaultFilters);
   };

   const onYearsCheckedChange = () => {
      setIsYearsChecked(!isYearsChecked);
   };

   return (
      <>
         <Button
            variant="outlined"
            startIcon={filterButtonIcon}
            sx={{ my: 2 }}
            onClick={() => setIsShowFilters(!isShowFilters)}
         >
            {filterButtonText}
         </Button>

         {isShowFilters && (
            <Box>
               <Filters
                  fields={FILTER_FIELDS}
                  filters={filters}
                  options={options}
                  onFilterChange={onFilterChange}
                  onClearFilterClick={onClearFilterClick}
               />
               <Divider sx={{ my: 3 }} />
            </Box>
         )}

         <Box sx={{ mb: 2 }}>
            <FormControlLabel
               control={<Switch checked={isYearsChecked} onChange={onYearsCheckedChange} />}
               label="Age groups / Years"
            />
         </Box>

         <TableWrapper>
            <TableContainer>
               <Table>
                  <TableHead>
                     <TableRow>
                        <TableHeadCell>#</TableHeadCell>
                        {columns}
                     </TableRow>
                  </TableHead>

                  <TableBody>{rows}</TableBody>
               </Table>
            </TableContainer>
         </TableWrapper>
      </>
   );
}
