import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import defaultLeaguePhoto from 'assets/default_league_photo.jpeg';
import squadInTouchLogo from 'assets/logoImage.png';
import trophyicon from 'assets/trophy.png';
import thirdplaceicon from 'assets/third.png';

interface FinalsFixedTabsProps {
  finalsEvents: any[];
  tournamentFieldPictureUrl: string;
}

interface MatchData {
  id: string;
  teamA: {
    logo?: string;
    name: string;
    score: number | string;
    schoolName: string;
  };
  teamB: {
    logo?: string;
    name: string;
    score: number | string;
    schoolName: string;
  };
}

interface BracketInfo {
  semifinals: MatchData[];
  final: MatchData | null;
  winner: { logo?: string; name: string } | null;
}

const BracketContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '400px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const BracketColumn = styled(Box)({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const TeamCard = styled(Box)({
  width: '300px',
  height: '50px', 
  backgroundColor: 'white',
  border: '1px solid #ccc',
  borderRadius: '6px',
  margin: '6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px',
});

const SchoolInfo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

const Logo = styled('img')({
  width: '60px',
  height: '60px',
  objectFit: 'cover',
  borderRadius: '6px',
});

const ScoreText = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '1.2rem',
  textAlign: 'right',
  minWidth: '40px',
});

const TrophyIcon = styled('img')({
  width: '60px',
  height: '60px',
});

const ThirdPlaceIcon = styled('img')({
  width: '60px',
  height: '60px',
});

const StyledSubTabs = styled(Tabs)({
  borderBottom: '2px solid #1976d2',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1976d2',
  },
});

const StyledSubTab = styled(Tab)({
  textTransform: 'none',
  fontWeight: 'bold',
  color: '#1976d2',
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: '#1976d2',
    borderRadius: '4px',
  },
});

function getLogo(team: any, invitedSchools: any[]): string {
  if (team.school?.pic) return team.school.pic;
  if (team.logo) return team.logo;
  const found = invitedSchools.find((s: any) => s.id === team.schoolId);
  return found?.pic || squadInTouchLogo;
}

function getSchoolName(team: any, invitedSchools: any[]): string {
  if (team.school?.name) return team.school.name;
  const found = invitedSchools.find((s: any) => s.id === team.schoolId);
  return found?.name || '';
}

function getPlayoffEventForCategory(events: any[], category: string): any | null {
  console.log('Checking playoff events for category:', category);
  const playoff = events.find((ev: any) => {
    const groupName = (ev.tournamentGroup?.name || '').toLowerCase();
    console.log('Examining event group:', groupName);
    return groupName.includes(category.toLowerCase()) && groupName.includes('playoff');
  }) || null;
  console.log('Found playoff event:', playoff);
  return playoff;
}

function parseEventToMatch(event: any): MatchData | null {
  if (!event.teamsData || event.teamsData.length < 2) return null;
  const invitedSchools = event.invitedSchools || [];
  const [tA, tB] = event.teamsData;
  let scoreA: number | string = '';
  let scoreB: number | string = '';
  if (event.results?.teamScore?.length) {
    const scoreMap: Record<string, number> = {};
    event.results.teamScore.forEach((ts: any) => {
      scoreMap[ts.teamId] = ts.score || 0;
    });
    scoreA = typeof scoreMap[tA.id] !== 'undefined' ? scoreMap[tA.id] : '';
    scoreB = typeof scoreMap[tB.id] !== 'undefined' ? scoreMap[tB.id] : '';
  }
  return {
    id: event.id,
    teamA: {
      logo: getLogo(tA, invitedSchools),
      name: tA.name || 'Team A',
      score: scoreA,
      schoolName: getSchoolName(tA, invitedSchools),
    },
    teamB: {
      logo: getLogo(tB, invitedSchools),
      name: tB.name || 'Team B',
      score: scoreB,
      schoolName: getSchoolName(tB, invitedSchools),
    },
  };
}

function buildBracketData(events: any[]): BracketInfo {
  const semifinalEvents = events
    .filter((ev: any) =>
      (ev.tournamentGroup?.name || '').toLowerCase().includes('semifinal')
    )
    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  const limitedSemifinalEvents = semifinalEvents.slice(0, 2);
  const finalEvent = events.find((ev: any) => {
    const name = ev.tournamentGroup?.name?.toLowerCase() || '';
    return /\bfinal\b/.test(name) && !/\bqtr\b/.test(name) && !/\bsemi\b/.test(name) && !/\bquarter\b/.test(name);
  });  
  
  const semifinals: MatchData[] = limitedSemifinalEvents
    .map((ev: any) => parseEventToMatch(ev))
    .filter(Boolean) as MatchData[];
  let final: MatchData | null = null;
  if (finalEvent) {
    final = parseEventToMatch(finalEvent);
  }
  let winner: { logo?: string; name: string } | null = null;
  if (final) {
    if (final.teamA.score > final.teamB.score) {
      winner = { logo: final.teamA.logo, name: `${final.teamA.schoolName} / ${final.teamA.name}` };
    } else if (final.teamB.score > final.teamA.score) {
      winner = { logo: final.teamB.logo, name: `${final.teamB.schoolName} / ${final.teamB.name}` };
    }
  }
  return { semifinals, final, winner };
}

function getMatchWinner(match: MatchData): { logo?: string; schoolName: string; name: string; score: number | string } | null {
  const scoreA = Number(match.teamA.score);
  const scoreB = Number(match.teamB.score);
  if (scoreA > scoreB) return match.teamA;
  if (scoreB > scoreA) return match.teamB;
  return null;
}

interface TeamNameDisplayProps {
  schoolName: string;
  teamName: string;
  bold?: boolean;
}
function TeamNameDisplay({ schoolName, teamName, bold }: TeamNameDisplayProps) {
  const truncationStyle = {
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };

  const textStyle = bold ? { ...truncationStyle, fontWeight: 'bold' } : truncationStyle;

  return (
    <Tooltip title={`${schoolName} / ${teamName}`}>
      <Box>
        <Typography variant="body2" sx={textStyle}>
          {schoolName}
        </Typography>
        <Typography variant="body2" sx={textStyle}>
          {teamName}
        </Typography>
      </Box>
    </Tooltip>
  );
}

export default function FinalsFixedTabs(props: FinalsFixedTabsProps) {
  const { finalsEvents, tournamentFieldPictureUrl } = props;
  const standardCategories = ['Cup', 'Plate', 'Bowl', 'Trophy', 'Shield'];
  const categoriesWithEvents = standardCategories.filter((category) =>
    finalsEvents.some((event) => {
      const groupName = event.tournamentGroup?.name || '';
      return groupName.toLowerCase().includes(category.toLowerCase());
    })
  );
  const [selectedCategory, setSelectedCategory] = useState<string>(
    categoriesWithEvents[0] || ''
  );
  const [selectedView, setSelectedView] = useState<'finals' | 'playoffs'>('finals');

  const mergedEvents = finalsEvents; 
  const filteredFinalsEvents = mergedEvents.filter((event) => {
    const groupName = event.tournamentGroup?.name || '';
    return groupName.toLowerCase().includes(selectedCategory.toLowerCase());
  });
  const handleTabChange = (event: React.SyntheticEvent, newCategory: string) => {
    setSelectedCategory(newCategory);
    setSelectedView('finals'); 
  };
  const bracketData = buildBracketData(filteredFinalsEvents);
  const hasPlayoff = getPlayoffEventForCategory(filteredFinalsEvents, selectedCategory) !== null;

  return (
    <Box sx={{ mt: 4 }}>
      <Tabs value={selectedCategory} onChange={handleTabChange} aria-label="Finals Categories Tabs">
        {categoriesWithEvents.map((category) => (
          <Tab key={category} label={category} value={category} />
        ))}
      </Tabs>
      {filteredFinalsEvents.length === 0 ? (
        <Typography sx={{ mt: 2 }}>No finals events for {selectedCategory}.</Typography>
      ) : (
        <>
          {hasPlayoff && (
            <StyledSubTabs
              value={selectedView}
              onChange={(e, newVal) => setSelectedView(newVal)}
              aria-label="View Tabs"
              sx={{ mt: 2 }}
            >
              <StyledSubTab label="Finals" value="finals" />
              <StyledSubTab label="3/4 Playoffs" value="playoffs" />
            </StyledSubTabs>
          )}
          {(!hasPlayoff || selectedView === 'finals') ? (
            <BracketView
              semifinals={bracketData.semifinals}
              final={bracketData.final}
              winner={bracketData.winner}
              backgroundUrl={tournamentFieldPictureUrl}
              selectedCategory={selectedCategory}
              filteredFinalsEvents={filteredFinalsEvents}
            />
          ) : (
            <PlayoffView
              playoffEvent={getPlayoffEventForCategory(filteredFinalsEvents, selectedCategory)}
              backgroundUrl={tournamentFieldPictureUrl}
            />
          )}
        </>
      )}
    </Box>
  );
}

interface BracketViewProps {
  semifinals: MatchData[];
  final: MatchData | null;
  winner: { logo?: string; name: string } | null;
  backgroundUrl: string;
  selectedCategory: string;
  filteredFinalsEvents: any[];
}

function BracketView({ semifinals, final, winner, backgroundUrl }: BracketViewProps) {
  const hasSemifinals = semifinals.length > 0;

  if (hasSemifinals) {
    const topSemifinal = semifinals[0];
    const bottomSemifinal = semifinals.length > 1 ? semifinals[1] : null;

    const topWinner = topSemifinal
      ? getMatchWinner(topSemifinal) || { logo: '', name: 'TBD', score: '', schoolName: '' }
      : { logo: '', name: 'TBD', score: '', schoolName: '' };
    const bottomWinner = bottomSemifinal
      ? getMatchWinner(bottomSemifinal) || { logo: '', name: 'TBD', score: '', schoolName: '' }
      : { logo: '', name: 'TBD', score: '', schoolName: '' };

    const topWinnerDisplay = `${topWinner.schoolName} / ${topWinner.name}`;
    const finalTeamADisplay = final ? `${final.teamA.schoolName} / ${final.teamA.name}` : '';

    const useSwappedScores = final && (topWinnerDisplay !== finalTeamADisplay);

    const derivedFinal: MatchData = {
      id: final ? final.id : 'derived-final',
      teamA: {
        ...topWinner,
        score: final
          ? (useSwappedScores ? final.teamB.score : final.teamA.score)
          : topWinner.score,
      },
      teamB: {
        ...bottomWinner,
        score: final
          ? (useSwappedScores ? final.teamA.score : final.teamB.score)
          : bottomWinner.score,
      },
    };

    return (
      <BracketContainer sx={{ backgroundImage: `url(${backgroundUrl})` }}>
        {/* Semifinals on the left */}
        <BracketColumn sx={{ left: '5%', top: '3%' }}>
          <SemifinalPosition topOrBottom="top" match={topSemifinal} />
        </BracketColumn>
        {bottomSemifinal ? (
          <BracketColumn sx={{ left: '5%', top: '55%' }}>
            <SemifinalPosition topOrBottom="bottom" match={bottomSemifinal} />
          </BracketColumn>
        ) : (
          <SemifinalBlankPosition topOrBottom="bottom" />
        )}

        {/* Final in the middle: Use derivedFinal so the order stays as topWinner then bottomWinner */}
        <BracketColumn sx={{ left: '42%', top: '30%' }}>
          {derivedFinal ? (
            <>
              <TeamCard>
                <SchoolInfo>
                  <Logo src={derivedFinal.teamA.logo || squadInTouchLogo} />
                  <TeamNameDisplay
                    schoolName={derivedFinal.teamA.schoolName}
                    teamName={derivedFinal.teamA.name}
                  />
                </SchoolInfo>
                {final && (
                  <ScoreText>
                    {derivedFinal.teamA.score !== '' ? derivedFinal.teamA.score : ''}
                  </ScoreText>
                )}
              </TeamCard>
              <TeamCard>
                <SchoolInfo>
                  <Logo src={derivedFinal.teamB.logo || squadInTouchLogo} />
                  <TeamNameDisplay
                    schoolName={derivedFinal.teamB.schoolName}
                    teamName={derivedFinal.teamB.name}
                  />
                </SchoolInfo>
                {final && (
                  <ScoreText>
                    {derivedFinal.teamB.score !== '' ? derivedFinal.teamB.score : ''}
                  </ScoreText>
                )}
              </TeamCard>
            </>
          ) : (
            <>
              <BlankTeamCard />
              <BlankTeamCard />
            </>
          )}
        </BracketColumn>

        <BracketColumn sx={{ right: '5%', top: '40%' }}>
          {winner ? (
            <TeamCard>
              <SchoolInfo>
                <Logo src={winner.logo || squadInTouchLogo} />
                {(() => {
                  const [winnerSchool, winnerTeam] = winner.name.split(' / ');
                  return (
                    <TeamNameDisplay
                      schoolName={winnerSchool}
                      teamName={winnerTeam}
                      bold
                    />
                  );
                })()}
              </SchoolInfo>
              <TrophyIcon src={trophyicon} alt="Trophy" />
            </TeamCard>
          ) : (
            <BlankTeamCard />
          )}
        </BracketColumn>
      </BracketContainer>
    );
  } else {
    // NO SEMIFINALS => display final and winner side-by-side
    return (
      <BracketContainer sx={{ backgroundImage: `url(${backgroundUrl})` }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '40px',
            width: '100%',
            position: 'absolute',
            top: '30%',
          }}
        >
          {/* Final Column */}
          <Box sx={{ width: '300px' }}>
            {final ? (
              <>
                <TeamCard>
                  <SchoolInfo>
                    <Logo src={final.teamA.logo || squadInTouchLogo} />
                    <TeamNameDisplay
                      schoolName={final.teamA.schoolName}
                      teamName={final.teamA.name}
                    />
                  </SchoolInfo>
                  <ScoreText>{final.teamA.score !== '' ? final.teamA.score : ''}</ScoreText>
                </TeamCard>
                <TeamCard>
                  <SchoolInfo>
                    <Logo src={final.teamB.logo || squadInTouchLogo} />
                    <TeamNameDisplay
                      schoolName={final.teamB.schoolName}
                      teamName={final.teamB.name}
                    />
                  </SchoolInfo>
                  <ScoreText>{final.teamB.score !== '' ? final.teamB.score : ''}</ScoreText>
                </TeamCard>
              </>
            ) : (
              <>
                <BlankTeamCard />
                <BlankTeamCard />
              </>
            )}
          </Box>

          {/* Winner Column */}
          <Box sx={{ width: '300px' }}>
            {winner ? (
              <TeamCard>
                <SchoolInfo>
                  <Logo src={winner.logo || squadInTouchLogo} />
                  {(() => {
                    const [winnerSchool, winnerTeam] = winner.name.split(' / ');
                    return (
                      <TeamNameDisplay
                        schoolName={winnerSchool}
                        teamName={winnerTeam}
                        bold
                      />
                    );
                  })()}
                </SchoolInfo>
                <TrophyIcon src={trophyicon} alt="Trophy" />
              </TeamCard>
            ) : (
              <BlankTeamCard />
            )}
          </Box>
        </Box>
      </BracketContainer>
    );
  }
}

interface PlayoffViewProps {
  playoffEvent: any | null;
  backgroundUrl: string;
}
function PlayoffView({ playoffEvent, backgroundUrl }: PlayoffViewProps) {
  const playoffMatch = playoffEvent ? parseEventToMatch(playoffEvent) : null;
  
  let playoffWinner: { logo?: string; name: string } | null = null;
  if (playoffMatch) {
    if (playoffMatch.teamA.score !== '' && playoffMatch.teamB.score !== '') {
      if (playoffMatch.teamA.score > playoffMatch.teamB.score) {
        playoffWinner = {
          logo: playoffMatch.teamA.logo,
          name: `${playoffMatch.teamA.schoolName} / ${playoffMatch.teamA.name}`,
        };
      } else if (playoffMatch.teamB.score > playoffMatch.teamA.score) {
        playoffWinner = {
          logo: playoffMatch.teamB.logo,
          name: `${playoffMatch.teamB.schoolName} / ${playoffMatch.teamB.name}`,
        };
      }
    }
  }

  return (
    <BracketContainer sx={{ backgroundImage: `url(${backgroundUrl})` }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '40px',
          width: '100%',
          position: 'absolute',
          top: '30%',
        }}
      >
        {/* Playoff Final Column */}
        <Box sx={{ width: '300px' }}>
          {playoffMatch ? (
            <>
              <TeamCard>
                <SchoolInfo>
                  <Logo src={playoffMatch.teamA.logo || squadInTouchLogo} />
                  <TeamNameDisplay
                    schoolName={playoffMatch.teamA.schoolName}
                    teamName={playoffMatch.teamA.name}
                  />
                </SchoolInfo>
                <ScoreText>
                  {playoffMatch.teamA.score !== '' ? playoffMatch.teamA.score : ''}
                </ScoreText>
              </TeamCard>
              <TeamCard>
                <SchoolInfo>
                  <Logo src={playoffMatch.teamB.logo || squadInTouchLogo} />
                  <TeamNameDisplay
                    schoolName={playoffMatch.teamB.schoolName}
                    teamName={playoffMatch.teamB.name}
                  />
                </SchoolInfo>
                <ScoreText>
                  {playoffMatch.teamB.score !== '' ? playoffMatch.teamB.score : ''}
                </ScoreText>
              </TeamCard>
            </>
          ) : (
            <>
              <BlankTeamCard />
              <BlankTeamCard />
            </>
          )}
        </Box>

        {/* Playoff Winner Column */}
        <Box sx={{ width: '300px' }}>
          {playoffWinner ? (
            <TeamCard>
              <SchoolInfo>
                <Logo src={playoffWinner.logo || squadInTouchLogo} />
                {(() => {
                  const [winnerSchool, winnerTeam] = playoffWinner.name.split(' / ');
                  return (
                    <TeamNameDisplay
                      schoolName={winnerSchool}
                      teamName={winnerTeam}
                      bold
                    />
                  );
                })()}
              </SchoolInfo>
              <TrophyIcon src={thirdplaceicon} alt="Trophy" />
            </TeamCard>
          ) : (
            <BlankTeamCard />
          )}
        </Box>
      </Box>
    </BracketContainer>
  );
}

interface SemifinalPositionProps {
  match: MatchData;
  topOrBottom: 'top' | 'bottom';
}
function SemifinalPosition({ match, topOrBottom }: SemifinalPositionProps) {
  const positionStyle = topOrBottom === 'top' ? { top: '3%' } : { top: '55%' };
  return (
    <BracketColumn sx={{ left: '5%', ...positionStyle }}>
      <TeamCard>
        <SchoolInfo>
          <Logo src={match.teamA.logo || squadInTouchLogo} />
          <TeamNameDisplay
            schoolName={match.teamA.schoolName}
            teamName={match.teamA.name}
          />
        </SchoolInfo>
        <ScoreText>{match.teamA.score !== '' ? match.teamA.score : ''}</ScoreText>
      </TeamCard>
      <TeamCard>
        <SchoolInfo>
          <Logo src={match.teamB.logo || squadInTouchLogo} />
          <TeamNameDisplay
            schoolName={match.teamB.schoolName}
            teamName={match.teamB.name}
          />
        </SchoolInfo>
        <ScoreText>{match.teamB.score !== '' ? match.teamB.score : ''}</ScoreText>
      </TeamCard>
    </BracketColumn>
  );
}

function SemifinalBlankPosition({ topOrBottom }: { topOrBottom: 'top' | 'bottom' }) {
  const positionStyle = topOrBottom === 'top' ? { top: '3%' } : { top: '55%' };
  return (
    <BracketColumn sx={{ left: '5%', ...positionStyle }}>
      <BlankTeamCard />
      <BlankTeamCard />
    </BracketColumn>
  );
}

function BlankTeamCard() {
  return (
    <TeamCard>
      <SchoolInfo>
        <Logo src={squadInTouchLogo} />
        <Typography variant="body2" sx={{ color: '#999' }}>
          TBD
        </Typography>
      </SchoolInfo>
      <ScoreText sx={{ color: '#999' }}>-</ScoreText>
    </TeamCard>
  );
}
