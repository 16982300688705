import * as propz from 'propz';
import Lazy from 'lazy.js';
import {
   SchoolEvent,
   SchoolEventTeamScore,
   SchoolEventTeamData,
   SchoolEventEmbeddedTeams,
   SchoolEventHouseScore,
   RichScore,
   SchoolEventTeamDataPlayer,
   SchoolEventIndividualScore,
   SchoolEventIndividualData,
   SchoolEventSchoolScore,
   SchoolEventSchool
} from 'models/event';
import { School } from 'models/school';
import { Sport } from 'models/sport';
import { Record } from 'models/record';
import { SchoolHouse } from 'models/house';
import { SCHOOL_AGE_GROUPS_NAMING_TYPE } from 'types/school';
import { POINTS_DISPLAY_TYPE, SPORT_GENDER_TYPE } from 'types/sport';
import { AGE_GROUPS } from 'consts/school';
import { CLUB_GENDER, CLUB_GENDER_SERVER_TO_CLIENT_MAPPING } from 'consts/club';
import { PLAYER_RESULTS_WHO_COULD_NOT_FINISH } from 'consts/player';
import {
   SCORING,
   SPORT_GENDER,
   SPORT_GENDER_SERVER_TO_CLIENT_MAPPING,
   SPORT_POINTS_TYPE
} from 'consts/sport';
import { sortSingleAgeEventsByAge } from './sorting';
import { sortByFieldDesc } from 'helpers/common';
import { isTriathlon } from './sport/sport';
import { isCricket, isTeamSportEvent } from './event';
import { isDuplexTournamentEvent } from './tournament';
import { isTeamOrTwoOnTwoSportEvent } from './event';
import { SUBTYPE_OF_TOURNAMENT } from 'consts/tournament';
import { CRICKET_RESULT } from 'consts/cricket';

export interface AdditionalData {
   isRecord?: boolean;
   permissionId?: string;
   score?: number;
   userId?: string;
   _id?: string;
   richScore?: RichScore;
   schoolUnionId?: string;
   point?: number;
   pointCount?: number;
   firstName?: string | string[];
   lastName?: string | string[];
   age?: string | string[];
   gender?: SPORT_GENDER_TYPE;
   schoolId?: string;
   events?: any[];
   pointTypeFromEvents?: POINTS_DISPLAY_TYPE[];
   placesFromEvents?: number[];
   pointsFromEvents?: (number | string)[];
   scoreFromEvents?: number[];
   isTeamResultFromEvents?: boolean[];
}

interface IndividualPlayersScoreUniq {
   isRecord?: boolean
   permissionId?: string
   score?: number
   userId?: string
   _id?: string
   richScore?: RichScore
   schoolUnionId?: string
   point?: number
   pointCount?: number
   firstName?: string
   lastName?: string
   age?: string
   gender?: SPORT_GENDER_TYPE
   schoolId?: string
}

interface ResultRow {
   Total: number;
   [key: string]: any;
   position?: number;
 }

export const OVERALL_RESULTS = 'Overall results';
export const JUNIOR_MAX_AGE = 7;

export function stringTimeToPoints(value: any, mask: string) {
   const maskParts = mask.replace(/[^hmsc]/g, ':').split(':');
   const valueParts = value.replace(/[^0-9_]/g, ':').split(':');

   let result = 0;
   for (let i = 0; i < maskParts.length; i++) {
      let currentMaskPart = maskParts[i];

      let tmp = 0;
      switch (true) {
         case isHourMask(currentMaskPart): {
            tmp = valueParts[i] * 3600;
            break;
         }
         case isMinuteMask(currentMaskPart): {
            if (valueParts[i] < 60) {
               tmp = valueParts[i] * 60;
            } else {
               tmp = NaN;
            }
            break;
         }
         case isSecondMask(currentMaskPart): {
            if (valueParts[i] < 60) {
               result += valueParts[i] * 1;
            } else {
               tmp = NaN;
            }
            break;
         }
         case isMillisecondMask(currentMaskPart): {
            if (typeof valueParts[i] !== 'undefined') {
               tmp = (valueParts[i] * 1) / Math.pow(10, valueParts[i].length);
            }
            break;
         }
         default:
            tmp = NaN;
            break;
      }
      if (isNaN(tmp)) {
         return NaN;
      }
      result += tmp;
   }

   return result;
}

export function stringDistanceToPoints(value: any, mask: string) {
   const maskParts = mask.replace(/[^kmc]/g, ':').split(':'),
      valueParts = value.replace(/[^0-9_]/g, ':').split(':');

   let result = 0;
   for (let i = 0; i < maskParts.length; i++) {
      let currentMaskPart = maskParts[i];

      let tmp = 0;
      switch (true) {
         case isKilometerMask(currentMaskPart): {
            tmp = valueParts[i] * 100000;
            break;
         }
         case isMeterMask(currentMaskPart): {
            if (valueParts[i] < 1000) {
               tmp = valueParts[i] * 100;
            } else {
               tmp = NaN;
            }
            break;
         }
         case isCentimeterMask(currentMaskPart): {
            if (valueParts[i] < 100) {
               result += valueParts[i] * 1;
            } else {
               tmp = NaN;
            }
            break;
         }
         default:
            tmp = NaN;
            break;
      }
      if (isNaN(tmp)) {
         return NaN;
      }
      result += tmp;
   }
   return result;
}

export function plainPointsToTimeString(value: any, mask: string) {
   let valueCopy = value;

   if (isNaN(value) || value === null) {
      valueCopy = 0;
   }

   const valueParts = String(valueCopy).split('.');
   const integerPartOfValue = Number(valueParts[0]);

   // just copy integer part of plain points
   let remainder = Number(integerPartOfValue);

   let timeString = '';

   const maskParts = mask.replace(/[^hmsc]/g, '.').split('.');

   maskParts.forEach((maskPart: string) => {
      switch (true) {
         case isHourMask(maskPart): {
            const hourCount = getCountOfCurrentTimeUnit(remainder, 'HOURS');
            // remove hours in sec from remainder
            remainder -= hourCount * 3600;

            const hourString = convertValueUnitToStringByMask(hourCount, maskPart, 'TIME');

            timeString = hourString + getDelimiterByMaskAndRegex(mask, HOUR_WITH_DELIMITER_REGEX);
            break;
         }
         case isMinuteMask(maskPart): {
            const minCount = getCountOfCurrentTimeUnit(remainder, 'MINUTES');
            // remove minutes in sec from remainder
            remainder -= minCount * 60;

            const minString = convertValueUnitToStringByMask(minCount, maskPart, 'TIME');

            timeString += minString + getDelimiterByMaskAndRegex(mask, MINUTE_WITH_DELIMITER_REGEX);
            break;
         }
         case isSecondMask(maskPart): {
            // at this step remainder is a fresh seconds without hours(in sec naturally) and minutes(in sec naturally)
            const secCount = remainder;

            const secString = convertValueUnitToStringByMask(secCount, maskPart, 'TIME');

            timeString += secString + getDelimiterByMaskAndRegex(mask, SECOND_WITH_DELIMITER_REGEX);
            break;
         }
         case isMillisecondMask(maskPart): {
            const roundedValueArray = valueCopy.toFixed(maskPart.length).split('.'),
               floatPartOfRoundedValue = String(typeof roundedValueArray[1] !== 'undefined' ? roundedValueArray[1] : 0);

            const msecString = convertValueUnitToStringByMask(floatPartOfRoundedValue, maskPart, 'TIME');

            timeString += msecString;
            break;
         }
      }
   });

   return timeString;
}

function getCountOfCurrentTimeUnit(value: any, timeUnit: string) {
   switch (timeUnit) {
      case 'HOURS':
         return Math.floor(value / 3600);
      case 'MINUTES':
         return Math.floor(value / 60);
      case 'SECONDS':
         return value;
      default:
         return 0;
   }
}

function getCountOfCurrentDistanceUnit(value: any, distanceUnit: string) {
   switch (distanceUnit) {
      case 'KILOMETERS':
         return Math.floor(value / 100000);
      case 'METERS':
         return Math.floor(value / 100);
      default:
         return 0;
   }
}

export function plainPointsToDistanceString(value: any, mask: string) {
   // just copy integer part of plain points
   let remainder = Number(value);

   let distanceString = '';

   const maskParts = mask.replace(/[^kmc]/g, ':').split(':');

   maskParts.forEach((maskPart) => {
      switch (true) {
         case isKilometerMask(maskPart): {
            const kmCount = getCountOfCurrentDistanceUnit(remainder, 'KILOMETERS');
            // remove km in cm from remainder
            remainder -= kmCount * 100000;

            const kmString = convertValueUnitToStringByMask(kmCount, maskPart, 'DISTANCE');

            distanceString = kmString + getDelimiterByMaskAndRegex(mask, KILOMETER_WITH_DELIMITER_REGEX);
            break;
         }
         case isMeterMask(maskPart): {
            const mCount = getCountOfCurrentDistanceUnit(remainder, 'METERS');
            // remove m in cm from remainder
            remainder -= mCount * 100;

            const mString = convertValueUnitToStringByMask(mCount, maskPart, 'DISTANCE');

            distanceString += mString + getDelimiterByMaskAndRegex(mask, METER_WITH_DELIMITER_REGEX);
            break;
         }
         case isCentimeterMask(maskPart): {
            // at this step remainder is a fresh cm without km(in cm naturally) and m(in cm naturally)
            const cmCount = remainder;

            const cmString = convertValueUnitToStringByMask(cmCount, maskPart, 'DISTANCE');

            distanceString += cmString;
            break;
         }
      }
   });

   return distanceString;
}

function isHourMask(mask: string) {
   return mask.search(HOUR_REGEX) !== -1;
}

function isMinuteMask(mask: string) {
   return mask.search(MINUTE_REGEX) !== -1;
}

function isSecondMask(mask: string) {
   return mask.search(SECOND_REGEX) !== -1;
}

function isMillisecondMask(mask: string) {
   return mask.search(MILLISECOND_REGEX) !== -1;
}

function isKilometerMask(mask: string) {
   return mask.search(KILOMETER_REGEX) !== -1;
}

function isMeterMask(mask: string) {
   return mask.search(METER_REGEX) !== -1;
}

function isCentimeterMask(mask: string) {
   return mask.search(CENTIMETER_REGEX) !== -1;
}

export const DEFAULT_TIME_MASK = 'hh:mm:ss.ccc';
export const DEFAULT_DISTANCE_MASK = 'kk mmm.cc';
const HOUR_REGEX = /h{1,}/i;
const HOUR_WITH_DELIMITER_REGEX = /h{1,}(.)/i;
const MINUTE_REGEX = /m{1,2}/i;
const MINUTE_WITH_DELIMITER_REGEX = /m{1,2}(.)/i;
const SECOND_REGEX = /s{1,2}/i;
const SECOND_WITH_DELIMITER_REGEX = /s{1,2}(.)/i;
const MILLISECOND_REGEX = /c{1,}/i;
const KILOMETER_REGEX = /k{1,3}/i;
const KILOMETER_WITH_DELIMITER_REGEX = /k{1,3}(.)/i;
const METER_REGEX = /m{1,3}/i;
const METER_WITH_DELIMITER_REGEX = /m{1,3}(.)/i;
const CENTIMETER_REGEX = /c{1,2}/i;

function convertValueUnitToStringByMask(value: any, mask: string, unit: string) {
   // convert value to string
   let result = String(value);

   switch (true) {
      case isCentimeterMask(mask) && unit === 'DISTANCE': {
         result = addZerosToStartByMask(result, mask);
         break;
      }
      // For milliseconds we have different rules than for other units
      // Because milliseconds are stored as fraction of float number
      // So we should add zeros to end if it need
      // Also we should cut off score string value
      // if value length more than mask length
      case isMillisecondMask(mask) && unit === 'TIME': {
         if (mask.length > result.length) {
            result = addZerosToEndByMask(result, mask);
         } else if (mask.length < result.length) {
            result = cutScoreValueByMask(result, mask);
         }
         break;
      }
      default: {
         result = addZerosToStartByMask(result, mask);
         break;
      }
   }

   return result;
}

function getDelimiterByMaskAndRegex(mask: string, regex: RegExp) {
   const result = mask.match(regex);

   const isResultExist = result !== null;

   return isResultExist && result[1];
}

function addZerosToEndByMask(stringValue: string, mask: string) {
   let result = String(stringValue);

   const zerosCount = mask.length - result.length;
   for (let i = 0; i < zerosCount; i++) {
      result = result + '0';
   }

   return result;
}

function addZerosToStartByMask(stringValue: string, mask: string) {
   let result = String(stringValue);

   const zerosCount = mask.length - result.length;
   for (let i = 0; i < zerosCount; i++) {
      result = '0' + result;
   }

   return result;
}

function cutScoreValueByMask(stringValue: string, mask: string) {
   return stringValue.substring(0, mask.length);
}

export function validateStringTime(value: string, mask: string): boolean {
   const points = stringTimeToPoints(value, mask);

   // Points must be a number but not a Nan or Infinite
   // Otherwise points are not valid
   return typeof points === 'number' && isFinite(points);
}

export function stringDistanceValidation(value: string, mask: string): boolean {
   const points = stringDistanceToPoints(value, mask);

   // Points must be a number but not a Nan or Infinite
   // Otherwise points are not valid
   return typeof points === 'number' && isFinite(points);
}

export function stringPlainValidation(value: string): boolean {
   const points = Number(value);
   // value must be a number but not a Nan or Infinite
   // Otherwise points are not valid
   return !isNaN(points) && isFinite(points);
}

export function getHouseBasicData(events: SchoolEvent[], eventsFiltered: SchoolEvent[], ageGroupsNaming: string) {
   const eventsWithSingleAge = Lazy(events)
      .filter((event: SchoolEvent) => {
         const eventTypeCondition = event.eventType === 'INTERNAL_HOUSES';
         const eventPointsCondition = event.resultsMode === 'POINTS';
         const ageCondition = event.ages.length === 1;

         return eventTypeCondition && eventPointsCondition && ageCondition;
      })
      .toArray();

   const eventsWithMultipleAges = Lazy(events)
      .filter((event: SchoolEvent) => {
         const eventTypeCondition = event.eventType === 'INTERNAL_HOUSES';
         const eventPointsCondition = event.resultsMode === 'POINTS';
         const isManyAges = event.ages.length > 1;

         return eventTypeCondition && eventPointsCondition && isManyAges;
      })
      .toArray();

   const filteredEventsWithSingleAge = Lazy(eventsFiltered)
      .filter((event: SchoolEvent) => {
         const eventTypeCondition = event.eventType === 'INTERNAL_HOUSES';
         const eventPointsCondition = event.resultsMode === 'POINTS';
         const ageCondition = event.ages.length === 1;

         return eventTypeCondition && eventPointsCondition && ageCondition;
      })
      .toArray();

   const filteredEventsWithMultipleAge = Lazy(eventsFiltered)
      .filter((event: SchoolEvent) => {
         const eventTypeCondition = event.eventType === 'INTERNAL_HOUSES';
         const eventPointsCondition = event.resultsMode === 'POINTS';
         const isManyAges = event.ages.length > 1;

         return eventTypeCondition && eventPointsCondition && isManyAges;
      })
      .toArray();

   const allEvents = [...eventsWithSingleAge, ...eventsWithMultipleAges];

   const houses = Lazy(allEvents)
      .map((event: SchoolEvent) => event.housesData)
      .flatten()
      .map((houseData: SchoolHouse) => ({
         houseId: houseData.id,
         name: houseData.name
      }))
      .uniq('houseId')
      .toArray();

   const allSingleAges = Lazy(eventsWithSingleAge)
      .map((event: SchoolEvent) => event.ages[0])
      .uniq()
      .toArray();

   const scores = houses.map((house) => {
      let resultObj: any = {
         'Team name': house.name,
         'Total': getPointsForHouses(eventsFiltered, house.houseId),
         'Number of events': getClosedGamesCountForHouses(eventsFiltered, house.houseId)
      };

      allSingleAges.forEach((age: number) => {
         const ageGroup = propz.get(AGE_GROUPS, [ageGroupsNaming, age]);
         const eventsFilteredByAge = filteredEventsWithSingleAge.filter((event: SchoolEvent) => event.ages[0] === age);

         const isScoresWithCurrentAgeExist = houses.some((house) => {
            const { houseId } = house;
            const points = getPointsForHouses(eventsFilteredByAge, houseId);

            return points > 0;
         });

         if (isScoresWithCurrentAgeExist) {
            resultObj = {
               ...resultObj,
               [ageGroup]: getPointsForHouses(eventsFilteredByAge, house.houseId)
            };
         }
      });

      const isScoresWithMixedAgeExist = houses.some((house) => {
         const { houseId } = house;
         const points = getPointsForHouses(filteredEventsWithMultipleAge, houseId);

         return points > 0;
      });

      if (isScoresWithMixedAgeExist) {
         resultObj['Mixed ages'] = getPointsForHouses(filteredEventsWithMultipleAge, house.houseId);
      }

      return resultObj;
   });

   const scoresSorted = scores.sort(sortByFieldDesc('Total'));

   return scoresSorted;
}

function getClosedGamesCountForHouses(events: SchoolEvent[], houseId: string): number {
   return Lazy(events)
      .map((event) => {
         const { housesData } = event;
         const house = housesData.find((houseData: SchoolHouse) => houseData.id === houseId);

         const result = typeof house !== 'undefined' ? 1 : 0;

         return result;
      })
      .sum();
}

function getPointsForHouses(events: SchoolEvent[], houseId: string): number {
   return Lazy(events)
      .map((event) => {
         const { teamsData, results } = event;

         const team = teamsData.find((teamData: SchoolEventTeamData) => teamData.houseId === houseId);
         const teamId = propz.get(team, ['id']);
         const teamResult = results.teamScore.find((score: SchoolEventTeamScore) => score.teamId === teamId);
         const houseResult = results.houseScore.find((score: SchoolEventHouseScore) => score.houseId === houseId);
         const teamScore = propz.get(teamResult, ['score'], 0);
         const houseScore = propz.get(houseResult, ['score'], 0);

         return teamScore + houseScore;
      })
      .sum();
}
//TODO Need refactoring
export function getHouseScoresTableColumns(scores: SchoolEventTeamScore[]) {
   const isScoresExist: boolean = typeof scores[0] !== 'undefined';

   if (!isScoresExist) {
      return [];
   }

   const columnsTitles: string[] = Object.keys(scores[0]);
   const columnsTitlesWithoutTotal: string[] = columnsTitles.filter((title) => title !== 'Total');

   const NUMBER_REGEXP: RegExp = /\d+/;

   const columnsTitlesSorted: string[] = columnsTitlesWithoutTotal.sort((nextTitle, currentTitle) => {
      NUMBER_REGEXP.lastIndex = 0;
      const nextTitleOverlapArray = nextTitle.match(NUMBER_REGEXP);

      NUMBER_REGEXP.lastIndex = 0;
      const currentTitleOverlapArray = currentTitle.match(NUMBER_REGEXP);

      const isOverlapsExist = nextTitleOverlapArray !== null && currentTitleOverlapArray !== null;

      return isOverlapsExist ? Number(nextTitleOverlapArray[0]) - Number(currentTitleOverlapArray[0]) : 0;
   });

   const isColumnWithTotalTitleExist: boolean = columnsTitles.some((title) => title === 'Total');

   if (isColumnWithTotalTitleExist) {
      columnsTitlesSorted.push('Total');
   }

   return columnsTitlesSorted;
}

export function getTeamsBasicData(events: SchoolEvent[]) {
   const eventsFiltered = Lazy(events)
      .filter((event: SchoolEvent) => {
         const eventTypeCondition = event.eventType === 'INTERNAL_TEAMS';
         const eventPointsCondition = event.resultsMode === 'POINTS';

         return eventTypeCondition && eventPointsCondition;
      })
      .toArray();

   const allTeamsUniq = Lazy(eventsFiltered)
      .map((event: SchoolEvent) => event.teamsData)
      .flatten()
      .map((teamData: SchoolEventTeamData) => ({
         cloneOf: teamData.cloneOf,
         name: teamData.name
      }))
      .uniq('cloneOf')
      .toArray();

   const embeddedTeams = eventsFiltered.map((event: SchoolEvent) => event.embeddedTeams);
   const allEmbeddedTeams = embeddedTeams.reduce(
      (result: SchoolEventEmbeddedTeams[], teams: SchoolEventEmbeddedTeams[]) => [...result, ...teams],
      []
   );

   const scores = allTeamsUniq
      .filter(team => {
         const { cloneOf } = team;

         const isCloneTeamExist = allEmbeddedTeams.some(
            (embeddedTeam: SchoolEventEmbeddedTeams) => embeddedTeam.cloneOf === cloneOf
         );

         return isCloneTeamExist;
      })
      .map(currentTeam => {
         const cloneOf = propz.get(currentTeam, ['cloneOf'], '');
         const name = propz.get(currentTeam, ['name'], '');

         const eventWithCurrentTeam = eventsFiltered.find((event: SchoolEvent) => {
            return event.embeddedTeams.some(
               (embeddedTeam: SchoolEventEmbeddedTeams) => embeddedTeam.cloneOf === cloneOf
            );
         });

         const isCurrentEventExist = typeof eventWithCurrentTeam !== 'undefined';

         return {
            'Team name': name,
            'Activity/Sport': isCurrentEventExist ? eventWithCurrentTeam.sport.name : '',
            'Number of events': getClosedGamesCountForTeam(eventsFiltered, cloneOf),
            'Total': getPointsForTeams(eventsFiltered, cloneOf)
         };
      });

   const scoresSorted = scores.sort(sortByFieldDesc('Total'));

   return scoresSorted;
}

function getClosedGamesCountForTeam(events: SchoolEvent[], cloneOf: string): number {
   return Lazy(events)
      .map(event => {
         const { embeddedTeams } = event;
         const team = embeddedTeams.find((embeddedTeam: SchoolEventEmbeddedTeams) => embeddedTeam.cloneOf === cloneOf);

         const result = typeof team !== 'undefined' ? 1 : 0;

         return result;
      })
      .sum();
}

function getPointsForTeams(events: SchoolEvent[], cloneOf: string): number {
   return Lazy(events)
      .map(event => {
         const { teamsData, results } = event;

         const team = teamsData.find((teamData: SchoolEventTeamData) => teamData.cloneOf === cloneOf);
         const teamId = propz.get(team, ['id']);
         const teamResult = results.teamScore.find((score: SchoolEventTeamScore) => score.teamId === teamId);
         const teamScore = propz.get(teamResult, ['score'], 0);

         return teamScore;
      })
      .sum();
}

export function getIndividualBasicData(
   players: SchoolEventIndividualData[],
   results: SchoolEventIndividualScore[],
   houses: SchoolHouse[],
   ageGroupsNaming: SCHOOL_AGE_GROUPS_NAMING_TYPE
) {
   const eventsCountWhichPlayersParticipate: any = {};

   players.forEach((item: SchoolEventIndividualData) => {
      const { userId } = item;

      eventsCountWhichPlayersParticipate[userId]
         ? eventsCountWhichPlayersParticipate[userId]++
         : (eventsCountWhichPlayersParticipate[userId] = 1);
   });

   const participatingPlayerUserIds = Object.keys(eventsCountWhichPlayersParticipate);

   const result = participatingPlayerUserIds
      .map(playerUserId => {
         const player: SchoolEventIndividualData | undefined = players.find(player => player.userId === playerUserId);

         const playerHouseId = propz.get(player, ['houseId']);
         const playerAge = propz.get(player, ['form', 'age']);
         const playerGender = propz.get(player, ['gender'], '');
         const playerFirstName = propz.get(player, ['firstName'], '');
         const playerLastName = propz.get(player, ['lastName'], '');
         const playerName = `${playerFirstName} ${playerLastName}`;

         const playerAllResults = results.filter((result) => result.userId === playerUserId);
         const eventWithResultCount = playerAllResults.length;
         const house = houses.find((house) => house.id === playerHouseId);
         const houseName = propz.get(house, ['name'], '');

         let allPoints = 0;

         playerAllResults.forEach(result => {
            const points = propz.get(result, ['richScore', 'points'], 0);
            allPoints += points;
         });

         const ageGroup = AGE_GROUPS[ageGroupsNaming];
         const age = ageGroup[playerAge];
         const gender = SPORT_GENDER_SERVER_TO_CLIENT_MAPPING[playerGender as SPORT_GENDER_TYPE];

         return {
            id: playerUserId,
            name: playerName,
            age,
            gender,
            houseName,
            challengesCount: eventsCountWhichPlayersParticipate[playerUserId],
            challengesWithResultCount: eventWithResultCount,
            points: allPoints
         };
      })
      .sort((player1, player2) => {
         switch (true) {
            case player1.points !== player2.points:
               return player2.points - player1.points;

            case player1.challengesWithResultCount !== player2.challengesWithResultCount:
               return player2.challengesWithResultCount - player1.challengesWithResultCount;

            case player1.challengesCount !== player2.challengesCount:
               return player2.challengesCount - player1.challengesCount;

            default:
               return player1.name.localeCompare(player2.name);
         };
      });

   return result;
}

export function getTournamentResultsFormattedByAgeGroups(
   events: SchoolEvent[],
   tournament: any 
 ) {
    const schoolList = getUniqSchoolEntriesFromEvents(events);
 
    const sportNames: string[] = (Lazy(events) as any)
       .map((event: SchoolEvent) =>
          event.sport.name.replace(/  +/g, ' ').trim()
       )
       .uniq()
       .toArray();
 
    sportNames.unshift(OVERALL_RESULTS);
 
    const scoresData: any = {};
    const isCrossCountry = tournament.subType === 'CROSS_COUNTRY' || tournament.subType === 'NATIONAL_CROSS_COUNTRY';
 
    sportNames.forEach(sportName => {
       const results = schoolList
          .map(school => {
             const eventsFilteredBySportAndSchool = events.filter(event => {
                const { invitedSchools } = event;
                const eventSportName = propz.get(event, ['sport', 'name'], '');
                const eventSportNameFormatted = eventSportName.replace(/ +/g, ' ').trim();
 
                return sportName === OVERALL_RESULTS
                   ? invitedSchools.some(invitedSchool => invitedSchool.name === school.name)
                   : eventSportNameFormatted === sportName &&
                        invitedSchools.some(invitedSchool => invitedSchool.name === school.name);
             });

             const total = isCrossCountry
                ? getCrossCountryPoints(eventsFilteredBySportAndSchool, school.id, tournament)
                : getPoints(eventsFilteredBySportAndSchool, school.id);
 
             const juniorEvents = eventsFilteredBySportAndSchool.filter(event => {
                return event.ages.every(age => age < JUNIOR_MAX_AGE);
             });
             const isJuniorEventsExist = juniorEvents.length > 0;
 
             const juniorBoysEvents = juniorEvents.filter(event => event.gender === CLUB_GENDER.MALE_ONLY);
             const juniorGirlsEvents = juniorEvents.filter(event => event.gender === CLUB_GENDER.FEMALE_ONLY);
 
             const seniorEvents = eventsFilteredBySportAndSchool.filter(event => {
                return event.ages.every(age => age >= JUNIOR_MAX_AGE);
             });
             const isSeniorEventsExist = seniorEvents.length > 0;
 
             const seniorBoysEvents = seniorEvents.filter(event => event.gender === CLUB_GENDER.MALE_ONLY);
             const seniorGirlsEvents = seniorEvents.filter(event => event.gender === CLUB_GENDER.FEMALE_ONLY);
 
             const juniorFields = isJuniorEventsExist
             ? {
                  'Junior Girls': isCrossCountry
                     ? getCrossCountryPoints(juniorGirlsEvents, school.id, tournament)
                     : getPoints(juniorGirlsEvents, school.id),
                  'Junior Boys': isCrossCountry
                     ? getCrossCountryPoints(juniorBoysEvents, school.id, tournament)
                     : getPoints(juniorBoysEvents, school.id)
               }
             : {};          
 
             const seniorFields = isSeniorEventsExist
             ? {
                  'Senior Girls': isCrossCountry
                     ? getCrossCountryPoints(seniorGirlsEvents, school.id, tournament)
                     : getPoints(seniorGirlsEvents, school.id),
                  'Senior Boys': isCrossCountry
                     ? getCrossCountryPoints(seniorBoysEvents, school.id, tournament)
                     : getPoints(seniorBoysEvents, school.id)
               }
             : {};
 
             return {
                'School name': school.name,
                ...juniorFields,
                ...seniorFields,
                'Total': total
             };
          })
          .sort(isCrossCountry ? sortByTotalCrossCountry : sortByTotal);
 
       scoresData[sportName] = results;
    });
 
    return {
       scores: scoresData,
       sportNames
    };
} 

export function getTournamentResultsFormattedByYears(
   events: SchoolEvent[],
   tournament: any 
 ) {
    const schoolList = getUniqSchoolEntriesFromEvents(events);
    const sportNames: string[] = (Lazy(events) as any)
       .map((event: SchoolEvent) => event.sport.name.replace(/ +/g, ' ').trim())
       .uniq()
       .toArray();
 
    sportNames.unshift(OVERALL_RESULTS);
 
    const eventsExtended = getEventsWithAgeAndGender(events);
    const competitions: string[] = (Lazy(eventsExtended) as any)
       .map((event: any) => event.competition)
       .uniq()
       .toArray();
 
    let scoresData: any = {};
 
    const isCrossCountry = tournament.subType === 'CROSS_COUNTRY' || tournament.subType === 'NATIONAL_CROSS_COUNTRY';
 
    sportNames.forEach(sportName => {
       const results = schoolList
          .map(school => {
             const eventsFilteredBySportAndSchool = eventsExtended.filter(event => {
                const { invitedSchools } = event;
                const eventSportName = propz.get(event, ['sport', 'name'], '');
                const eventSportNameFormatted = eventSportName.replace(/ +/g, ' ').trim();
 
                return sportName === OVERALL_RESULTS
                   ? invitedSchools.some(invitedSchool => invitedSchool.name === school.name)
                   : eventSportNameFormatted === sportName &&
                         invitedSchools.some(invitedSchool => invitedSchool.name === school.name);
             });
 
             let results: { [key: string]: number } = {};
 
             competitions.forEach(competition => {
                const eventsFiltered = eventsFilteredBySportAndSchool
                   .filter(event => event.competition === competition);
 
                results[competition] = isCrossCountry
                   ? getCrossCountryPoints(eventsFiltered, school.id, tournament)
                   : getPoints(eventsFiltered, school.id);
             });
 
             let schoolData = { 'School name': school.name };
 
             return {
                ...schoolData,
                ...results,
                'Total': isCrossCountry
                   ? getCrossCountryPoints(eventsFilteredBySportAndSchool, school.id, tournament)
                   : getPoints(eventsFilteredBySportAndSchool, school.id)
             };
          })
          .sort(isCrossCountry ? sortByTotalCrossCountry : sortByTotal);
 
       scoresData[sportName] = results;
    });
    return {
       scores: scoresData,
       sportNames
    };
} 

export function assignPositions(sortedResults: ResultRow[]): ResultRow[] {
   let currentRank = 1;
   sortedResults.forEach((result, index) => {
     if (index === 0) {
       result.position = currentRank;
     } else {
       if (result.Total === sortedResults[index - 1].Total) {
         result.position = sortedResults[index - 1].position;
       } else {
         result.position = index + 1;
       }
     }
   });
   return sortedResults;
}

function getPoints(events: SchoolEvent[], schoolId: string): number {
   const getPlayerSchoolId = (event: SchoolEvent, userId: string, permissionId: string): string => {
      const player = event.individuals
         .find(individual => individual.userId === userId && individual.permissionId === permissionId);

      const isPlayerExist = typeof player !== 'undefined';

         return isPlayerExist ? player.schoolId : '';
   };

   const getTeamSchoolId = (event: SchoolEvent, teamId: string): string => {
      const team = event.embeddedTeams.find(team => team._id === teamId);
      const isTeamExist = typeof team !== 'undefined';

      return isTeamExist ? team.schoolId : '';
   };

   const getPointsFromScore = (score: any): number => {
      return propz.get(score, ['richScore', 'points'], 0);
   };

   return Lazy(events)
      .filter(event => {
         const isHideResultOnPublicSites = propz.get(event, ['showAsNotClosedOnPublicSite'], false);

         return !isHideResultOnPublicSites;
      })
      .map(event => {
         const { schoolScore, teamScore, individualScore } = event.results;

         const isIndividualScoreExist = typeof individualScore !== 'undefined';
         const isTeamScoreExist = typeof teamScore !== 'undefined';
         const isSchoolScoreExist = typeof schoolScore !== 'undefined';

         if (isIndividualScoreExist) {
            const currentSchoolIndividualScore = individualScore
               .filter(scoreItem => {
                  const playerSchoolId = getPlayerSchoolId(event, scoreItem.userId, scoreItem.permissionId);

                  return schoolId === playerSchoolId;
               });

            if (currentSchoolIndividualScore.length > 0) {
               const result = Lazy(currentSchoolIndividualScore)
                  .map(score => getPointsFromScore(score))
                  .sum();

               return result;
            }
         };

         if (isTeamScoreExist) {
            const currentSchoolTeamScore = teamScore
               .filter(scoreItem => schoolId === getTeamSchoolId(event, scoreItem.teamId));

            if (currentSchoolTeamScore.length > 0) {
               const result = Lazy(currentSchoolTeamScore)
                  .map(score => getPointsFromScore(score))
                  .sum();

               return result;
            }
         };

         if (isSchoolScoreExist) {
            const currentSchoolScore = schoolScore
               .filter(scoreItem => schoolId === scoreItem.schoolId);

            if (currentSchoolScore.length > 0) {
               const result = Lazy(currentSchoolScore)
                  .map(score => getPointsFromScore(score))
                  .sum();

               return result;
            }
         };

         return 0;
      })
      .sum();
}

const getPlayerSchoolId = (event: SchoolEvent, userId: string, permissionId: string): string => {
   const player = event.players.find(
      player => player.userId === userId && player.permissionId === permissionId
   );
   return player ? player.schoolId : '';
};

function getCrossCountryPointsForEvent(
   event: SchoolEvent,
   schoolId: string,
   tournament: any
 ): number {

   const scoring = propz.get(event, ['sport', 'scoring'], SCORING.LESS_TIME);
   const scores: SchoolEventIndividualScore[] = propz.get(event, ['results', 'individualScore'], []);

  const validScores = scores.filter(score => (score.richScore?.result ?? 0) !== 0);
  if (validScores.length === 0) {
    return 0;
  }

   const sortedScores = [...validScores].sort((a, b) => {
      const resultA = a.richScore?.result ?? Number.MAX_VALUE; 
      const resultB = b.richScore?.result ?? Number.MAX_VALUE;
      return resultA - resultB;
    });   
   const scoresWithPosition = sortedScores.map((score, idx) => ({
     ...score,
     finishingPosition: idx + 1
   }));
 
   const schoolScores = scoresWithPosition.filter(score => {
     const playerSchoolId = getPlayerSchoolId(event, score.userId, score.permissionId);
     return playerSchoolId === schoolId;
   });

   const eventAge: number = event.ages[0];
   let genderKey: string;
   if (event.gender === 'MALE_ONLY') {
     genderKey = 'B';
   } else if (event.gender === 'FEMALE_ONLY') {
     genderKey = 'G';
   } else {
     genderKey = 'B';
   }
   const posSetting = tournament.positionsCountForTeamPoints.find((s: any) =>
     s.year === eventAge && s.gender === genderKey
   );
   const count = posSetting ? posSetting.count : schoolScores.length;
   
   const topScores = schoolScores.sort((a, b) => a.finishingPosition - b.finishingPosition)
                                  .slice(0, count);

   const eventPoints = topScores.reduce((sum, score) => sum + score.finishingPosition, 0);
 
   return eventPoints;
}
 
function getCrossCountryPoints(events: SchoolEvent[], schoolId: string, tournament: any): number {
return events.reduce((sum, event) => {
   return sum + getCrossCountryPointsForEvent(event, schoolId, tournament);
}, 0);
}

function getUniqSchoolEntriesFromEvents(events: SchoolEvent[]): { id: string; name: string }[] {
   return (Lazy(events) as any)
      .map((event: SchoolEvent) => event.invitedSchools)
      .flatten()
      .uniq('id')
      .map((school: School) => ({ id: school.id, name: school.name }))
      .toArray();
}

function sortByTotal(data1: any, data2: any) {
   switch (true) {
      case data1['Total'] > data2['Total']:
         return -1;
      case data1['Total'] < data2['Total']:
         return 1;
      default:
         return 0;
   }
}

function sortByTotalCrossCountry(data1: any, data2: any): number {
   const total1 = data1['Total'];
   const total2 = data2['Total'];
   
   if (total1 === 0 && total2 === 0) {
     return 0;
   }
   if (total1 === 0) return 1; 
   if (total2 === 0) return -1;
   
   return total1 - total2;
}

function getEventsWithAgeAndGender(events: SchoolEvent[]) {
   return events.sort(sortSingleAgeEventsByAge)
      .map(event => {
         const eventMinAge = Math.min(...event.ages);
         const eventMaxAge = Math.max(...event.ages);
         const age = eventMinAge === eventMaxAge ? `Year ${eventMinAge}` : `Year ${eventMinAge}-${eventMaxAge}`;

         const gender = CLUB_GENDER_SERVER_TO_CLIENT_MAPPING[event.gender];

         return { ...event, competition: `${age} ${gender}` };
      });
}

export function getBestPerformanceData(events: SchoolEvent[], records: Record[]) {
   const bestPerformanceData = events.map(event => {
      const eventMinAge = Math.min(...event.ages);
      const eventMaxAge = Math.max(...event.ages);
      const age = eventMinAge === eventMaxAge ? `Year ${eventMinAge}` : `Year ${eventMinAge}-${eventMaxAge}`;

      const gender = CLUB_GENDER_SERVER_TO_CLIENT_MAPPING[event.gender];

      const isTeamSport = isTeamSportEvent(event);

      const individualScoreSorted: SchoolEventIndividualScore[] = sortIndividualScoreByScore(event);
      const teamScoreSorted: SchoolEventIndividualScore[] = sortTeamScoreByScore(event);

      const scoresSorted = isTeamSport ? teamScoreSorted : individualScoreSorted;
      const isDistanceOrTimeTournamentEventCondition: boolean = isDistanceOrTimeTournamentEvent(event);

      const scoresFiltered: SchoolEventIndividualScore[] = scoresSorted
         .filter(score => {
            const result = propz.get(score, ['richScore', 'result']);
            const isPlayerCouldNotFinish = PLAYER_RESULTS_WHO_COULD_NOT_FINISH.includes(result as string);
            const isResultExist = result !== 0 && !isPlayerCouldNotFinish;

            return isDistanceOrTimeTournamentEventCondition
               ? isResultExist
               : true;
         });

      const firstPlayerScore: SchoolEventIndividualScore = scoresFiltered[0];
      const playerRichScoreResult = propz.get(scoresFiltered[0], ['richScore', 'result']);

      const inviterSchool = propz.get(event, ['inviterSchool']);
      const invitedSchools = propz.get(event, ['invitedSchools'], []);
      const participants: SchoolEventSchool[] = [inviterSchool, ...invitedSchools];

      const getFirstTeamData = () => {
         const {teamsData} = event;

         const firstPlayerScoreSchoolId = propz.get(firstPlayerScore, ['schoolId']);
         const isFirstPlayerScoreFromSchoolScore = typeof firstPlayerScoreSchoolId !== 'undefined';

         if (isFirstPlayerScoreFromSchoolScore) {
            return participants.find(school => {
               return school.id === firstPlayerScoreSchoolId;
            })
         } else {
            return teamsData.find(team => {
               return typeof firstPlayerScore !== 'undefined'
                  ? team.id === firstPlayerScore.teamId
                  : false;
            });
         }
      };

      // TODO: fix it (from old front)
      // individualsResults can be empty, so firstParticipantScore === undefined
      const firstTeamData = getFirstTeamData();

      const firstPlayerData: SchoolEventIndividualData | undefined =
         event.individualsData.find(individual => {
            return typeof firstPlayerScore !== 'undefined'
               ? individual.userId === firstPlayerScore.userId
               : false;
         });

      const firstTeamSchoolId = propz.get(firstTeamData, ['schoolId'], '');
      const firstTeamSchool = participants.find(school => school.id === firstTeamSchoolId);
      const firstTeamSchoolName = propz.get(firstTeamSchool, ['name'], '');
      const firstTeamName = propz.get(firstTeamData, ['name'], firstTeamSchoolName)

      const isFirstPlayerDataExist = typeof firstPlayerData !== 'undefined';
      const isFirstTeamDataExist = typeof firstTeamData !== 'undefined';

      const firstParticipantResult = (isFirstPlayerDataExist || isFirstTeamDataExist)
         ? getResult(playerRichScoreResult, event)
         : '0';

         const eventDateStr = propz.get(event, ['startTime'], ''); 
         const eventDate = new Date(eventDateStr).getTime(); 

      const recordsOfCurrentEvent = records.filter(record => {
         const recordMinAge = Math.min(...record.ages);
         const recordMaxAge = Math.max(...record.ages);
         const isAgeEqual = recordMinAge === eventMinAge && recordMaxAge === eventMaxAge;

         const isGenderEqual = getClubGenderConvertedFromSportGender(record.gender) === event.gender;
         const isSportIdEqual = record.sportId === event.sportId;

         const recordDate = new Date(record.date).getTime();
         const isRecordEarlier = recordDate < eventDate;

         return isAgeEqual && isGenderEqual && isSportIdEqual;
      });

      const record = recordsOfCurrentEvent.length > 0 ? findClosestRecordByDate(recordsOfCurrentEvent, eventDateStr) : undefined;
      const recordAthlete = propz.get(record, ['athlete'], '');
      const isRecordExist = typeof record !== 'undefined';
      const currentRecord = isRecordExist ? getCurrentRecordString(record, event) : '';
      const newRecordLabel = isRecordExist ? getNewRecordLabel(record, firstPlayerScore, event) : '';

      const firstName = propz.get(firstPlayerData, ['firstName'], '');
      const lastName = propz.get(firstPlayerData, ['lastName'], '');
      const schoolName = isTeamSport ? firstTeamName : propz.get(firstPlayerData, ['schoolName'], '');

      const athlete = isTeamSport
         ? recordAthlete
         : `${firstName} ${lastName}`

      return {
         'Event': `${event.sport.name}`,
         'EventId': `${event.id}`,
         'Group': `${age} ${gender}`,
         'Athlete': athlete,
         'Team': `${schoolName}`,
         'Result': `${firstParticipantResult}`,
         'Current record': `${currentRecord}`,
         '': `${newRecordLabel}`
      };
   });

   return bestPerformanceData;
}

export function getEventsWithResultForWinnersTable(events: SchoolEvent[]) {
   return events.filter(event => {
       if (event.status !== 'FINISHED') {
           return false;
       }

       const isTeamSport = isTeamSportEvent(event);
       const individualScoreSorted: SchoolEventIndividualScore[] = sortIndividualScoreByScore(event);
       const teamScoreSorted: SchoolEventIndividualScore[] = sortTeamScoreByScore(event);

       const scoresSorted = isTeamSport ? teamScoreSorted : individualScoreSorted;

       const isDistanceOrTimeTournamentEventCondition: boolean = isDistanceOrTimeTournamentEvent(event);

       const scoresFiltered: SchoolEventIndividualScore[] = scoresSorted.filter(score => {
           const result = propz.get(score, ['richScore', 'result']);
           const isPlayerCouldNotFinish = PLAYER_RESULTS_WHO_COULD_NOT_FINISH.includes(result.toString());
           const isResultExist = result !== 0 && !isPlayerCouldNotFinish;

           return isDistanceOrTimeTournamentEventCondition ? isResultExist : true;
       });

       let firstPlayerScore: SchoolEventIndividualScore | undefined;
       let playerRichScoreResult: number | undefined;
       let hasValidScore: boolean = false;

       if (scoresFiltered.length > 0) {
           firstPlayerScore = scoresFiltered[0];
           playerRichScoreResult = propz.get(firstPlayerScore, ['richScore', 'result']);
           hasValidScore = true;
       } else {
           const validTeamOrSchoolScore = scoresSorted.find(score => {
               const result = propz.get(score, ['richScore', 'result'], 0);
               const isPlayerCouldNotFinish = PLAYER_RESULTS_WHO_COULD_NOT_FINISH.includes(result.toString());
               return result !== 0 && !isPlayerCouldNotFinish;
           });

           if (validTeamOrSchoolScore) {
               firstPlayerScore = validTeamOrSchoolScore;
               playerRichScoreResult = propz.get(validTeamOrSchoolScore, ['richScore', 'result']);
               hasValidScore = true;
           } else {
               return false;
           }
       }

       const firstTeamData: SchoolEventTeamData | undefined = event.teamsData.find(team => {
           return firstPlayerScore ? team.id === firstPlayerScore.teamId : false;
       });

       const firstPlayerData: SchoolEventIndividualData | undefined = event.individualsData.find(individual => {
           return firstPlayerScore ? individual.userId === firstPlayerScore.userId : false;
       });

       const firstTeamName = propz.get(firstTeamData, ['name'], '');
       const isFirstPlayerDataExist = typeof firstPlayerData !== 'undefined';
       const isFirstTeamDataExist = typeof firstTeamData !== 'undefined';

       const firstParticipantResult = hasValidScore
           ? getResult(playerRichScoreResult ?? 0, event)
           : '0';

       const shouldIncludeEvent = firstParticipantResult !== '0';

       return shouldIncludeEvent;
   });
}

function findClosestRecordByDate(records: Record[], eventDateStr: string) {
   const eventDateTimestamp = new Date(eventDateStr).getTime();
   let closestRecord: Record | undefined = undefined;
   let closestDateDifference = Infinity;

   records.forEach(record => {
      const recordDateTimestamp = new Date(record.date).getTime();
      const dateDifference = eventDateTimestamp - recordDateTimestamp; 

      if (dateDifference > 0 && dateDifference < closestDateDifference) {
         closestRecord = record;
         closestDateDifference = dateDifference;
      }
   });

   return closestRecord;
}

function getNewRecordLabel(
   record: Record,
   firstPlayerScore: SchoolEventIndividualScore,
   event: SchoolEvent
): string {
   const isFirstPlayerScoreExist = typeof firstPlayerScore !== 'undefined';
   const isRecordExist = typeof record !== 'undefined';
   const { id: eventId } = event;
   const { id: recordEventId } = record;

   switch (true) {
      case !isFirstPlayerScoreExist:
         return '';

      case !isRecordExist:
         return firstPlayerScore.isRecord ? 'NR' : '';

      case recordEventId === eventId:
         return 'NR';
   }

   const scoring: string = propz.get(event, ['sport', 'scoring'], SCORING.MORE_SCORES);

   let newRecordLabel = '';
   const rischScoreResult = propz.get(firstPlayerScore, ['richScore', 'result']);

   switch (true) {
      case scoring === SCORING.MORE_SCORES:
      case scoring === SCORING.MORE_TIME:
      case scoring === SCORING.MORE_RESULT:
      case scoring === SCORING.FIRST_TO_N_POINTS:
         if (record.record < rischScoreResult) {
            newRecordLabel = 'NR';
         }

         break;

      default:
         if (record.record > rischScoreResult) {
            newRecordLabel = 'NR';
         }
   }

   return newRecordLabel;
}

function sortIndividualScoreByScore(event: SchoolEvent): SchoolEventIndividualScore[] {
   const score: SchoolEventIndividualScore[] = propz.get(event, ['results', 'individualScore'], []);

   return [...score].sort((score1, score2) => {
      const scoring: string = propz.get(event, ['sport', 'scoring'], SCORING.MORE_SCORES);
      const richScore1 = propz.get(score1, ['richScore', 'result'], 0);
      const richScore2 = propz.get(score2, ['richScore', 'result'], 0);
      const richScorePoints1 = propz.get(score1, ['richScore', 'points'], 0);
      const richScorePoints2 = propz.get(score2, ['richScore', 'points'], 0);

      switch (true) {
         case richScore1 === richScore2:
            // scores are equal, sort by richScore.points DESC regardless of the scoring,
            // P.S I don't now why it's always DESC but I would not be surprised if this changes
            return richScorePoints2 - richScorePoints1;
         case scoring === SCORING.MORE_SCORES:
         case scoring === SCORING.MORE_TIME:
         case scoring === SCORING.MORE_RESULT:
         case scoring === SCORING.FIRST_TO_N_POINTS:
            return richScore2 - richScore1;
         default:
            return richScore1 - richScore2;
      }
   });
}

function sortTeamScoreByScore(event: SchoolEvent): SchoolEventIndividualScore[] {
   const teamScore: SchoolEventIndividualScore[] = propz.get(event, ['results', 'teamScore'], []);
   const schoolScore: SchoolEventIndividualScore[] = propz.get(event, ['results', 'schoolScore'], []);

   return [...teamScore, ...schoolScore].sort((score1, score2) => {
       const scoring: string = propz.get(event, ['sport', 'scoring'], SCORING.MORE_SCORES);
       const richScore1 = propz.get(score1, ['richScore', 'result'], 0);
       const richScore2 = propz.get(score2, ['richScore', 'result'], 0);
       const richScorePoints1 = propz.get(score1, ['richScore', 'points'], 0);
       const richScorePoints2 = propz.get(score2, ['richScore', 'points'], 0);

       switch (true) {
           case richScore1 === richScore2:
               return richScorePoints2 - richScorePoints1;
           case scoring === SCORING.MORE_SCORES:
           case scoring === SCORING.MORE_TIME:
           case scoring === SCORING.MORE_RESULT:
           case scoring === SCORING.FIRST_TO_N_POINTS:
               return richScore2 - richScore1;
           default:
               return richScore1 - richScore2;
       }
   });
}

function isDistanceOrTimeTournamentEvent(event: SchoolEvent) {
   const eventSportPointsDisplay = propz.get(event, ['sport', 'points', 'display'], '');
   const isTournamentEvent = typeof event.tournamentId !== 'undefined';
   const isTimeEvent = eventSportPointsDisplay === SPORT_POINTS_TYPE.TIME;
   const isDistanceEvent = eventSportPointsDisplay === SPORT_POINTS_TYPE.DISTANCE;

   return (isDistanceEvent || isTimeEvent) && isTournamentEvent;
}

function getResult(result: number, event: SchoolEvent): string {
   const eventSportPointsDisplay = propz.get(event, ['sport', 'points', 'display'], '');
   const eventSportName = propz.get(event, ['sport', 'name'], '');
   const isTriathlonCondition = isTriathlon(eventSportName);

   if (isTriathlonCondition) {
      const pointsConverted = convertPoints(result, eventSportPointsDisplay);
      const hours = propz.get(pointsConverted, ['h'], 0);
      const minutes = propz.get(pointsConverted, ['min'], 0);
      const seconds = propz.get(pointsConverted, ['sec'], 0);

      return `${zeroPrint(hours)}:${zeroPrint(minutes)}:${zeroPrint(seconds)}`;
   } else {
      return getConvertedScore(result, event.sport);
   }
}

export function convertPoints(countPoints: number, pointsType: string) {
   const zeroPrintMs = (number: number): string => {
      if (number === 0) return '00';
      if (number < 10) return `00${number}`;
      if (number < 100) return `0${number}`;

      return number.toString();
   };

   const getTimeResult = (countPoints: number) => {
      const sec_in_hours = 3600;
      const sec_in_min = 60;
      const points = Math.floor(countPoints);
      const ms = Math.round((countPoints - points) * 1000);
      const h = Math.floor(points / sec_in_hours);
      const min = Math.floor((points - h * sec_in_hours) / sec_in_min);
      const sec = points - h * sec_in_hours - min * sec_in_min;

      let str = '';

      if (h) {
         str += h + '.';
      }

      if (min) {
         str += zeroPrint(min) + '.';
      }

      if (sec) {
         str += zeroPrint(sec) + '.';
      }

      // 1.999 -> 1.999
      // 1.990 -> 1.99
      // 1.900 -> 1.90
      // 1.090 -> 1.09
      if (ms >= 100 && ms % 10 === 0) {
         str += points === 0 ? '0.' + Math.floor(ms / 10) : Math.floor(ms / 10);
      } else if (ms > 10 && ms < 100 && ms % 10 === 0) {
         str += ms ? zeroPrint(Math.floor(ms / 10)) : '';
      } else {
         str += zeroPrintMs(ms);
      }

      if (str === '00') {
         str = '0';
      }

      return {
         h,
         min,
         sec,
         ms,
         str: str.trim()
      };
   };

   const getDistanceResult = (countPoints: number) => {
      const cm_in_km = 100000;
      const cm_in_m = 100;
      const km = Math.floor(countPoints / cm_in_km);
      const m = Math.floor((countPoints - km * cm_in_km) / cm_in_m);
      const cm = countPoints - km * cm_in_km - m * cm_in_m;

      let str = '';

      str += km ? km + '.' : '';
      str += m ? m + '.' : '';
      str += cm >= 10 ? cm : '0' + cm;

      return {
         km,
         m,
         cm,
         str: str.trim()
      };
   };

   switch (pointsType) {
      case SPORT_POINTS_TYPE.PLAIN:
         return {
            str: typeof countPoints !== 'undefined' ? countPoints.toString() : '-'
         };

      case SPORT_POINTS_TYPE.TIME:
         return getTimeResult(countPoints);

      case SPORT_POINTS_TYPE.DISTANCE:
         return getDistanceResult(countPoints);

      case SPORT_POINTS_TYPE.PRESENCE_ONLY:
         return {
            str: countPoints === 1 ? 'Present' : 'Absent'
         };

      default:
         return { str: '' };
   }
}

function getCurrentRecordString(record: Record, event: SchoolEvent): string {
   const { id: eventId } = event;
   const { id: recordEventId } = record;
   const athlete = propz.get(record, ['athlete']);
   const area = propz.get(record, ['area']);
   const recordDate = propz.get(record, ['date']);
   const isAthleteExist = typeof athlete !== 'undefined';
   const isAreaExist = typeof area !== 'undefined';
   const isRecordDateExist = typeof recordDate !== 'undefined';

   let result;

   if (recordEventId === eventId) {
      return '';
   }

   const recordResult = getResult(record.record, event);

   result = recordResult;

   if (isAthleteExist) {
      result += ` ${athlete}`;
   }

   if (isAreaExist) {
      result += ` (${area})`;
   }

   if (isRecordDateExist) {
      const date = new Date(recordDate);
      const year = date.getFullYear();

      result += ` ${year}`;
   }

   return result;
}

export function zeroPrint(number: number): string {
   if (number < 10) return `0${number}`;

   return number.toString();
}

export function getClubGenderConvertedFromSportGender(gender: string) {
   switch (gender) {
      case SPORT_GENDER.FEMALE:
         return CLUB_GENDER.FEMALE_ONLY;
      case SPORT_GENDER.MALE:
         return CLUB_GENDER.MALE_ONLY;
      default:
         return undefined;
   }
}

export function getConvertedScore(score: number, sport: Sport) {
   const pointsDisplay = propz.get(sport, ['points', 'display'], 'PLAIN');
   const inputMask = propz.get(sport, ['points', 'inputMask'], DEFAULT_TIME_MASK);

   switch (pointsDisplay) {
      case SPORT_POINTS_TYPE.PLAIN:
         return convertPoints(score, pointsDisplay).str;

      case SPORT_POINTS_TYPE.TIME:
         return plainPointsToTimeString(score, inputMask);

      case SPORT_POINTS_TYPE.DISTANCE:
         return plainPointsToDistanceString(score, inputMask);

      case SPORT_POINTS_TYPE.PRESENCE_ONLY:
         return convertPoints(score, pointsDisplay).str;

      default:
         console.error(`Undefined points display type: ${pointsDisplay}`);
         return '';
   }
}

export function getSchoolsAdditionalData(events: SchoolEvent[], schoolId: string, schoolName: string): AdditionalData[] {
   const schoolResults = (Lazy(events) as any)
      .filter((event: SchoolEvent) => isTeamOrTwoOnTwoSportEvent(event))
      .map((event: SchoolEvent) => {
         const isDuplexTournamentEventCondition = isDuplexTournamentEvent(event);
         const teamResults: SchoolEventTeamScore[] = propz.get(event, ['results', 'teamScore'], []);

         const teamResultsWithSchoolId = teamResults
            .map(teamResult => {
               const team = event.teamsData.find(teamData => teamData.id === teamResult.teamId);
               const teamSchoolId = propz.get(team, ['schoolId']);

               return {
                  ...teamResult,
                  schoolId: teamSchoolId
               };
            }
         );

         const schoolResults = propz.get(event, ['results', 'schoolScore'], []);

         const emptyResults = (Lazy(teamResultsWithSchoolId) as any)
            .concat(schoolResults)
            .flatten()
            .filter((result: any) => {
               const points = propz.get(result, ['richScore', 'points'], 0);
               const score = propz.get(result, ['score'], 0);

               return points === 0 && score === 0;
            })
            .toArray();

         const notEmptyResultsSorted = (Lazy(teamResultsWithSchoolId) as any)
            .concat(schoolResults)
            .flatten()
            .filter((result: any) => {
               const points = propz.get(result, ['richScore', 'points'], 0);
               const score = propz.get(result, ['score'], 0);

               return points !== 0 || score !== 0;
            })
            .sort((result1: any, result2: any) => {
               const points1 = propz.get(result1, ['richScore', 'points'], 0);
               const points2 = propz.get(result2, ['richScore', 'points'], 0);
               const eventScoring = event.sport.scoring;

               if (points2 === points1) {
                  const score1 = propz.get(result1, ['score'], 0);
                  const score2 = propz.get(result2, ['score'], 0);
                  switch (eventScoring) {
                     case SCORING.LESS_SCORES:
                     case SCORING.LESS_TIME:
                     case SCORING.LESS_RESULT:
                        return score1 - score2;
                     case SCORING.MORE_SCORES:
                     case SCORING.MORE_TIME:
                     case SCORING.MORE_RESULT:
                     case SCORING.FIRST_TO_N_POINTS:
                        return score2 - score1;
                     default:
                        return 0;
                  }
               } else {
                  return points2 - points1;
               }
            })
            .toArray();

         const allResults = [...notEmptyResultsSorted, ...emptyResults];

         const teamsData: SchoolEventTeamData[] = propz.get(event, ['teamsData'], []);
         const team: SchoolEventTeamData | undefined = event.teamsData
            .find((teamData: any) => {
               if (isDuplexTournamentEventCondition) {
                  const currentSchool: any = event.invitedSchools.find((school: any) => {
                     return school.schoolIds.includes(schoolId);
                  });

                  return teamData.schoolId === currentSchool.id
               }
               return teamData.schoolId === schoolId
            });
         const players: SchoolEventTeamDataPlayer[] = typeof team !== 'undefined' ? team.players : [];
         let firstName, lastName, age;

         switch (true) {
            case teamsData.length === 0:
            case players.length === 0:
               firstName = schoolName;
               lastName = 'Team';
               age = 'Y' + propz.get(event, ['ages', 0]);

               break;
            case players.length > 0:
               firstName = players.map((player) => player.firstName);
               lastName = players.map((player) => player.lastName);
               age = players.map((player) => {
                  const playerFormAge = propz.get(player, ['form', 'age'], '');

                  return 'Y' + playerFormAge
               });

               break;
         }

         const schoolIndex = allResults.findIndex(result => {
            if (isDuplexTournamentEventCondition) {
               const school = event.invitedSchools.find((invitedSchool: any) => {
                  return invitedSchool.schoolIds.some((id: string) => id === schoolId);
               });
               const isSchoolExist = typeof school !== 'undefined';

               return isSchoolExist && result.schoolId === school.id;
            } else {
               return result.schoolId === schoolId;
            }
         });

         const point = propz.get(allResults, [schoolIndex, 'richScore', 'points'], 0);
         const score = propz.get(allResults, [schoolIndex, 'richScore', 'result'], 0);

         const eventSportPointsDisplay = propz.get(event, ['sport', 'points', 'display'], '');

         return {
            firstName: firstName,
            lastName: lastName,
            age: age,
            event: event,
            pointType: eventSportPointsDisplay,
            place: schoolIndex + 1,
            point: point,
            score: score,
            isTeamResultFromEvents: true
         };
      })
      .toArray();

   return schoolResults.map((result: any) => {
      return {
         firstName: Array.isArray(result.firstName) ? result.firstName : [result.firstName],
         lastName: Array.isArray(result.lastName) ? result.lastName : [result.lastName],
         age: result.age,
         events: [result.event],
         pointTypeFromEvents: [result.pointType],
         placesFromEvents: [result.place],
         pointsFromEvents: [result.point],
         point: result.point,
         scoreFromEvents: [result.score],
         isTeamResultFromEvents: [result.isTeamResultFromEvents],
      };
   });
}

export function getPlayersAdditionalData(events: SchoolEvent[], schoolId: string, schoolName: string): AdditionalData[] {
   const individualPlayers = (Lazy(events) as any)
      .map((event: SchoolEvent) => {
         const individualsData: SchoolEventIndividualData[] = propz.get(event, ['individualsData'], []);

         return individualsData.map((individualData: SchoolEventIndividualData) => {
            const schoolId = getSchoolIdFromEventByPlayerUserId(event, individualData.userId);
            const isSchoolExist = typeof schoolId !== 'undefined';

            if (isSchoolExist) {
               return { ...individualData, schoolUnionId: schoolId };
            }

            return individualData;
         });
      })
      .flatten()
      .toArray();

   const individualPlayersScore = (Lazy(events) as any)
         .map((event: SchoolEvent) => {
            const individualScore: SchoolEventIndividualScore[] = propz.get(event, ['results', 'individualScore'], []);

            return individualScore.map(individualScore => {
               const schoolId = getSchoolIdFromEventByPlayerUserId(event, individualScore.userId);
               const isSchoolExist = typeof schoolId !== 'undefined';
               
               if (isSchoolExist) {
                  return { ...individualScore, schoolUnionId: schoolId }
               }

               return individualScore;
            });
         })
         .flatten()
         .toArray();

   const individualPlayersScoreWithUniqUserId: IndividualPlayersScoreUniq[] =
      (Lazy(individualPlayersScore) as any).uniq('userId').toArray();
   const individualPlayersDataWithUniqUserId: (SchoolEventIndividualData & {schoolUnionId?: string})[] =
      (Lazy(individualPlayers) as any).uniq('userId').toArray();

   let playersInfo;
   let individualPlayer;

   if (individualPlayersScoreWithUniqUserId.length !== 0 ) {
      playersInfo = individualPlayersDataWithUniqUserId.map(playerUniq => {
         const playerIndividualPoint: number = (Lazy(individualPlayersScore) as any)
            .map((individualPlayer: any) => {
               return individualPlayer.userId === playerUniq.userId
                  ? propz.get(individualPlayer, ['richScore', 'points'], 0)
                  : 0;
            })
            .sum();

         const playerIndividualPointCount: number = (Lazy(individualPlayersScore) as any)
            .map((individualPlayer: any) => {
               return individualPlayer.userId === playerUniq.userId ? 1 : 0;
            })
            .sum();

         individualPlayer = individualPlayersDataWithUniqUserId.find(individualPlayer => {
            return individualPlayer.userId === playerUniq.userId;
         });

         const age = propz.get(individualPlayer, ['form', 'age', '']);
         const isAgeExist = age !== '';

         //it may be for relays
         if (typeof individualPlayer !== 'undefined') {
            return {
               ...playerUniq,
               firstName: individualPlayer.firstName,
               lastName: individualPlayer.lastName,
               age: isAgeExist ? 'Y' + age : '',
               gender: individualPlayer.gender,
               schoolId: individualPlayer.schoolId,
               point: playerIndividualPoint,
               pointCount: playerIndividualPointCount
            };
         } else {
            return undefined; //filter it later
         }
      })
      .filter(playerInfo => typeof playerInfo !== 'undefined');
   } else {
      playersInfo = individualPlayersDataWithUniqUserId.map(playerUniq => {
         individualPlayer = individualPlayersDataWithUniqUserId
            .find(individualPlayer => individualPlayer.userId === playerUniq.userId);

         const age = propz.get(individualPlayer, ['form', 'age', '']);
         const isAgeExist = age !== '';

         if (typeof individualPlayer !== 'undefined') {
            return {
               ...playerUniq,
               firstName: individualPlayer.firstName,
               lastName: individualPlayer.lastName,
               age: isAgeExist ? 'Y' + age : '',
               gender: individualPlayer.gender,
               schoolId: individualPlayer.schoolId,
               point: 0,
               pointCount: 0
            };
         } else {
            return undefined; //filter it later
         }
      })
      .filter(playerInfo => typeof playerInfo !== 'undefined');
   }

   const playersAdditionalData: AdditionalData[] = playersInfo
      .filter((player: any) => {
         if (isISA2017() || isISA2018()) {
            let schoolNameFromLastName = getSchoolNameFromLastName(player.lastName || '');

            if (typeof schoolNameFromLastName !== 'undefined') {
               //dirty hack
               schoolNameFromLastName = schoolNameCorrector(schoolNameFromLastName);
               return (schoolNameFromLastName.toLowerCase() === schoolName.toLowerCase());
               /*||
               (SchoolNameFromLastName.indexOf(schoolName) !== -1) ||
               (schoolName.indexOf(SchoolNameFromLastName) !== -1)
               */
            } else {
               return false;
            }
         } else {
            return player.schoolId === schoolId || player.schoolUnionId === schoolId;
         }
      })
      .map((player: any) => {
         const playerEvents = events.filter((event) => {
            const isPlayerIndividual = event.individualsData.some(
               (individualData) => individualData.userId === player.userId
            );
            return isPlayerIndividual;
         });

         const playerEventsCopy = [...playerEvents];

         const playerResults = playerEventsCopy.map((event) => {
            const playerScore = event.results.individualScore.find((score) => score.userId === player.userId);

            let playerResult = 0,
               playerPoints: number | string = 0,
               playerPlace = 0;

            let isTeamResult = false;

            if (playerResult === 0) {
               playerResult = propz.get(playerScore, ['richScore', 'result'], 0);
               playerPoints = propz.get(playerScore, ['richScore', 'points'], 0);

               const sortedIndividualScore: SchoolEventIndividualScore[] = sortPlayersByExtraScore(
                  event,
                  event.results.individualScore
               );

                  //delete duplicate data coming from the server
                  //I do not know why data can be duplicated during input
                  //maybe it server error, maybe front
                  //TODO: Play and fix this error
                  const sortedIndividualScoreUniq = (Lazy(sortedIndividualScore) as any).uniq('userId').toArray();
                  playerPlace = sortedIndividualScoreUniq
                     .findIndex((score: any) => score.userId === player.userId) + 1;
               }

            if (isTriathlon(event.sport.name)) {
               isTeamResult = false;
            }

            return {
               placesFromEvents: playerPlace,
               pointsFromEvents: playerPoints,
               scoreFromEvents: playerResult,
               isTeamResultFromEvents: isTeamResult
            };
         });

         const playerWithData: AdditionalData = {
            ...player,
            events: playerEventsCopy,
            pointTypeFromEvents: playerEventsCopy.map((event: any) => event.sport.points.display),
            placesFromEvents: playerResults.map((playerResult) => playerResult.placesFromEvents),
            pointsFromEvents: playerResults.map((playerResult) => playerResult.pointsFromEvents),
            scoreFromEvents: playerResults.map((playerResult) => playerResult.scoreFromEvents),
            isTeamResultFromEvents: playerResults.map((playerResult) => playerResult.isTeamResultFromEvents)
         };

         return playerWithData;
      });

   return playersAdditionalData;
}

function getSchoolIdFromEventByPlayerUserId(event: any, userId: string): string | undefined {
   const playerData = event.players.find((player: any) => player.userId === userId);

   if (typeof playerData !== 'undefined') {
      return playerData.schoolId;
   } else {
      console.error(`Can not find player with id: ${userId}`);
      return undefined;
   }
}

function getSchoolNameFromLastName(lastName: string): string | undefined {
   // For ISA tournaments, players have the name of the school in the lastName field
   // Example: St Joseph's School
   const splittedLastName = lastName.split(',');
   const schoolName = splittedLastName[1] ? splittedLastName[1].trim() : undefined;

   return schoolName;
}

/** convert school name obtained in event to real school name */
function schoolNameCorrector(schoolNameFromEvent: string): string {
   switch (schoolNameFromEvent.toLowerCase()){
      case 'ACS Egham International School'.toLowerCase(): return 'ACS International School, Egham';
      case 'St Andrew\'s School Bedford'.toLowerCase(): return 'St Andrew\'s School';
      case 'St Christopher\'s School'.toLowerCase(): return 'St Christophers School';
      case 'Wilmslow Preparatory School'.toLowerCase(): return 'Wilmslow Prep School';
      case 'Adcote School for Girls'.toLowerCase(): return 'Adcote School';
      case 'Roselyon Preparatory School'.toLowerCase(): return 'Roselyon School';
      case 'St Hilda\'s School'.toLowerCase(): return 'St Hilda\'s School Preparatory School for Girls';
      default: return schoolNameFromEvent;
   }
}

function sortPlayersByExtraScore(event: SchoolEvent, players: any): SchoolEventIndividualScore[] {
   const scoring: string = propz.get(event, ['sport', 'scoring'], SCORING.MORE_SCORES);
   return [...players].sort((player1: any, player2: any) => {
      switch (true) {
         case player2.richScore.points < player1.richScore.points:
            return -1;
         case player2.richScore.points > player1.richScore.points:
            return 1;
         case player2.richScore.points === player1.richScore.points:
            if (
               scoring === SCORING.MORE_SCORES ||
               scoring === SCORING.MORE_TIME ||
               scoring === SCORING.MORE_RESULT ||
               scoring === SCORING.FIRST_TO_N_POINTS
            ) {
               switch (true) {
                  case player2.score < player1.score:
                     return -1;
                  case player2.score > player1.score:
                     return 1;
                  default:
                     return 0;
               }
            } else {
               switch (true) {
                  case player2.score > player1.score:
                     return -1;
                  case player2.score < player1.score:
                     return 1;
                  default:
                     return 0;
               }
            }
      }

      return 0;
   });
}

function isISA2017(): boolean {
   const schoolDomain = document.location.host.split('.')[0];

   return schoolDomain === 'isaathletics2017';
}

function isISA2018(): boolean {
   const schoolDomain = document.location.host.split('.')[0];

   return schoolDomain === 'isaathletics2018';
}

export function getFootballTournamentStagesScoreData(
   ids: any,
   events: any,
   teams: any,
   tournamentScoring: any,
   subTypeTournament: string,
   halfScoreForNetball: boolean
) {
   const scoresData: any = [];

   const isCricket = subTypeTournament === SUBTYPE_OF_TOURNAMENT.CRICKET;
   const isRugby = subTypeTournament === SUBTYPE_OF_TOURNAMENT.RUGBY;
   const isNetball = subTypeTournament === SUBTYPE_OF_TOURNAMENT.NETBALL;
   const isSailing = subTypeTournament === SUBTYPE_OF_TOURNAMENT.SAILING;

   ids.forEach((idObject: any) => {
      events.forEach((event: any) => {
         const id = idObject.id;
         const isSchoolId = idObject.kind === 'school';
         const isSchoolIdInTeam = idObject.isSchoolIdInTeam;
         const isTeamId = idObject.kind === 'team';
         
         let isIdConsistInEvent = false;
         
         switch (true){
            case isSchoolId && event.teamsData.length === 0:
               isIdConsistInEvent = isSchoolConsistInEvent(id, event);
               break;

            case isTeamId && typeof idObject.isSchoolIdInTeam === 'undefined':
               isIdConsistInEvent = isTeamConsistInEvent(id, event);
               break;

            case isTeamId && typeof idObject.isSchoolIdInTeam !== 'undefined':
               isIdConsistInEvent = isTeamConsistInEvent(id, event, isSchoolIdInTeam);
               break;
         }
         
         if (isIdConsistInEvent) {
            switch (true){
               case isCricket: {
                  const name = isSchoolId
                     ? getSchoolNameFromEvent(id, event)
                     : getTeamNameFromEvent(id, event, teams, isSchoolIdInTeam);

                  const winGame = isSchoolId
                     ? getSchoolCricketWinGame(id, event)
                     : getTeamCricketWinGame(id, event, isSchoolIdInTeam);

                  const drawGame = getTeamCricketDrawGame(event);

                  const loseGame = isSchoolId
                     ? getSchoolCricketLoseGame(id, event)
                     : getTeamCricketLoseGame(id, event, isSchoolIdInTeam);

                  const points = getPointsOfAllGames(winGame, drawGame, 0, loseGame, tournamentScoring);
                  const rf = getRF(id, event, isSchoolIdInTeam);
                  const ra = getRA(id, event, isSchoolIdInTeam);
                  const playGame = getPlayGame(event);

                  scoresData.push({
                     schoolName: name,
                     'P': playGame,
                     'W': winGame,
                     'D': drawGame,
                     'L': loseGame,
                     'RF': rf,
                     'RA': ra,
                     'Points': points
                  });
                  break;
               }

               case isSailing: {
                  const name = getTeamNameFromEvent(id, event, teams);
                  const captain = getCaptain(id, event);
                  const crew = getCrew(id, captain, event);
                  const points = getSailingPoints(id, event);
                  
                  scoresData.push({
                     //in sailing can be team with same school name and team name
                     //so I must distinguish it
                     schoolName: `${id} ${name}`,
                     'Captain': captain,
                     'Crew': crew,
                     'Total': points,
                     'eventStatus': event.status
                  });
                  break;
               }

               case isRugby: {
                  const name = isSchoolId
                     ? getSchoolNameFromEvent(id, event)
                     : getTeamNameFromEvent(id, event, teams);

                  const winGame = isSchoolId
                     ? getSchoolWinGame(id, event)
                     : getTeamWinGame(id, event);

                  const drawGame = isSchoolId
                     ? getSchoolDrawGame(id, event)
                     : getTeamDrawGame(id, event);

                  const loseGame = isSchoolId
                     ? getSchoolLoseGame(id, event)
                     : getTeamLoseGame(id, event);

                  const drawGameWithoutGoals = isSchoolId
                     ? getSchoolDrawGameWithoutGoals(id, event)
                     : getTeamDrawGameWithoutGoals(id, event);

                  const drawGameWithGoals = isSchoolId
                     ? getSchoolDrawGameWithGoals(id, event)
                     : getTeamDrawGameWithGoals(id, event);

                  const points = getPointsOfAllGames(
                     winGame,
                     drawGameWithGoals,
                     drawGameWithoutGoals,
                     loseGame,
                     tournamentScoring
                  );

                  const gf = isSchoolId
                     ? getSchoolGF(id, event)
                     : getTeamGF(id, event);

                  const ga = isSchoolId
                     ? getSchoolGA(id, event)
                     : getTeamGA(id, event);

                  const gd = getGD(gf, ga);
                  const playGame = getPlayGame(event);

                  scoresData.push({
                     schoolName: name,
                     'P': playGame,
                     'W': winGame,
                     'D': drawGame,
                     'L': loseGame,
                     'PF': gf,
                     'PA': ga,
                     '+/-': gd,
                     'Points': points,
                     'Win %': playGame ? (winGame*100 / playGame) : 0
                  });
                  break;
               }

               case isNetball: {
                  const name = isSchoolId
                     ? getSchoolNameFromEvent(id, event)
                     : getTeamNameFromEvent(id, event, teams);

                  const playGame = getPlayGame(event);
                  if (!playGame) {
                     scoresData.push({
                        schoolName: name,
                        'P': playGame,
                        'W': 0,
                        'D': 0,
                        'L': 0,
                        'GF': 0,
                        'GA': 0,
                        'GD': 0,
                        'Points': 0
                     });
                     break;
                  }

                  const teamScore = isSchoolId ? getSchoolGF(id, event) : getTeamGF(id, event);
                  const opponentScore = isSchoolId ? getSchoolGA(id, event) : getTeamGA(id, event);
                  
                  if (teamScore === null || teamScore === undefined || opponentScore === null || opponentScore === undefined) {
                     scoresData.push({
                        schoolName: name,
                        'P': playGame,
                        'W': 0,
                        'D': 0,
                        'L': 0,
                        'GF': 0,
                        'GA': 0,
                        'GD': 0,
                        'Points': 0
                     });
                     break;
                  }

                  let gameResult = 'lose';
                  if (teamScore > opponentScore) {
                     gameResult = 'win';
                  } else if (teamScore === opponentScore) {
                     gameResult = 'draw';
                  }

                  const points = getNetballPointsForSingleGame(
                     gameResult,
                     teamScore,
                     opponentScore,
                     tournamentScoring,
                     halfScoreForNetball ?? false
                  );

                  const gf = teamScore;
                  const ga = opponentScore;
                  const gd = getGD(gf, ga);

                  scoresData.push({
                     schoolName: name,
                     'P': playGame,
                     'W': gameResult === 'win' ? 1 : 0,
                     'D': gameResult === 'draw' ? 1 : 0,
                     'L': gameResult === 'lose' ? 1 : 0,
                     'GF': gf,
                     'GA': ga,
                     'GD': gd,
                     'Points': points
                  });
                  break;
               }

               default: {
                  const name = isSchoolId
                     ? getSchoolNameFromEvent(id, event)
                     : getTeamNameFromEvent(id, event, teams);

                  const winGame = isSchoolId
                     ? getSchoolWinGame(id, event)
                     : getTeamWinGame(id, event);

                  const drawGame = isSchoolId
                     ? getSchoolDrawGame(id, event)
                     : getTeamDrawGame(id, event);

                  const loseGame = isSchoolId
                     ? getSchoolLoseGame(id, event)
                     : getTeamLoseGame(id, event);

                  const drawGameWithoutGoals = isSchoolId
                     ? getSchoolDrawGameWithoutGoals(id, event)
                     : getTeamDrawGameWithoutGoals(id, event);

                  const drawGameWithGoals = isSchoolId
                     ? getSchoolDrawGameWithGoals(id, event)
                     : getTeamDrawGameWithGoals(id, event);

                  const points = getPointsOfAllGames(
                     winGame,
                     drawGameWithGoals,
                     drawGameWithoutGoals,
                     loseGame,
                     tournamentScoring
                  );

                  const gf = isSchoolId
                     ? getSchoolGF(id, event)
                     : getTeamGF(id, event);

                  const ga = isSchoolId
                     ? getSchoolGA(id, event)
                     : getTeamGA(id, event);

                  const gd = getGD(gf, ga);
                  const playGame = getPlayGame(event);
                  
                  scoresData.push({
                     schoolName: name,
                     'P': playGame,
                     'W': winGame,
                     'D': drawGame,
                     'L': loseGame,
                     'GF': gf,
                     'GA': ga,
                     'GD': gd,
                     'Points': points
                  });
               }
            }
         }
      });
   });
   
   const schoolsName = scoresData.map((scoreData: any) => scoreData.schoolName);
   const schoolsNameUniqArray = uniqueArray(schoolsName);

   const schoolsNameUniq = schoolsNameUniqArray.map((schoolName: any) => {
      if (isCricket) {
         return {
            schoolName: schoolName,
            'P': 0,
            'W': 0,
            'D': 0,
            'L': 0,
            'RF': 0,
            'RA': 0,
            'Points': 0
         };
      } else if (isSailing) {
         const schoolIndex = scoresData.findIndex((score: any) => score.schoolName === schoolName);
         return {
            schoolName: schoolName,
            'Captain': scoresData[schoolIndex].Captain,
            'Crew': scoresData[schoolIndex].Crew,
            'Total': 0
         };
      } else if (isRugby) {
         return {
            schoolName: schoolName,
            'P': 0,
            'W': 0,
            'D': 0,
            'L': 0,
            'PF': 0,
            'PA': 0,
            '+/-': 0,
            'Points': 0,
            'Win %': 0
         };
      } else {
         return {
            schoolName: schoolName,
            'P': 0,
            'W': 0,
            'D': 0,
            'L': 0,
            'GF': 0,
            'GA': 0,
            'GD': 0,
            'Points': 0
         };
      }
   });

   schoolsNameUniq.forEach((schoolEntry: any) => {
      let count = 0;
      scoresData.forEach((scoreData: any) => {
         if (schoolEntry.schoolName === scoreData.schoolName) {
            if (isCricket) {
               schoolEntry.P += Number(scoreData.P);
               schoolEntry.W += Number(scoreData.W);
               schoolEntry.D += Number(scoreData.D);
               schoolEntry.L += Number(scoreData.L);
               schoolEntry.RF += Number(scoreData.RF);
               schoolEntry.RA += Number(scoreData.RA);
               schoolEntry.Points += Number(scoreData.Points);
            } else if (isSailing) {
               schoolEntry.Total += Number(scoreData.Total);
               schoolEntry[`R${count + 1}`] = scoreData.eventStatus === 'FINISHED' ? Number(scoreData.Total) : '-';
               count++;
            } else if (isRugby) {
               schoolEntry.P += Number(scoreData.P);
               schoolEntry.W += Number(scoreData.W);
               schoolEntry.D += Number(scoreData.D);
               schoolEntry.L += Number(scoreData.L);
               schoolEntry.PF += Number(scoreData.PF);
               schoolEntry.PA += Number(scoreData.PA);
               schoolEntry['+/-'] += Number(scoreData['+/-']);
               schoolEntry.Points += Number(scoreData.Points);
            } else {
               schoolEntry.P += Number(scoreData.P);
               schoolEntry.W += Number(scoreData.W);
               schoolEntry.D += Number(scoreData.D);
               schoolEntry.L += Number(scoreData.L);
               schoolEntry.GF += Number(scoreData.GF);
               schoolEntry.GA += Number(scoreData.GA);
               schoolEntry.GD += Number(scoreData.GD);
               schoolEntry.Points += Number(scoreData.Points);
            }
         }
      });
      if (isRugby) {
         schoolEntry['Win %'] = schoolEntry.P ? Math.round(schoolEntry.W * 100 / schoolEntry.P) : 0;
      }
   });
   
   // For sailing, remove the id prefix from the school name.
   if (isSailing) {
      schoolsNameUniq.forEach((schoolEntry: any) => {
         const idLength = ids[0].id.length;
         schoolEntry.schoolName = schoolEntry.schoolName.substring(idLength + 1);
      });
   }

   return schoolsNameUniq;
}

function isSchoolConsistInEvent(schoolId: string, event: SchoolEvent){
   return event.inviterSchoolId === schoolId || event.invitedSchoolIds.some(invitedSchoolId => invitedSchoolId === schoolId);
}

function isTeamConsistInEvent(teamId: string, event: SchoolEvent, isSchoolId: boolean = false){
   if (isSchoolId) {
      return event.teamsData.some(teamData => teamData.schoolId === teamId);
   } else {
      return event.teamsData.some(teamData => teamData.cloneOf === teamId);
   }
};

function getSchoolNameFromEvent(schoolId: string, event: SchoolEvent) {
   if (schoolId === event.inviterSchoolId) {
      return propz.get(event, ['inviterSchool', 'name']);
   } else {
      const invitedSchool = event.invitedSchools.find(school => school.id === schoolId);

      return propz.get(invitedSchool, ['name']);
   }
};

function getTeamNameFromEvent(
   teamId: string,
   event: SchoolEvent,
   teams: any,
   isSchoolIdInTeam = false
){
   let name;

   const team = isSchoolIdInTeam ?
      event.teamsData.find(teamData => teamData.schoolId === teamId) :
      event.teamsData.find(teamData => teamData.cloneOf === teamId);

   if (typeof team === 'undefined'){
      console.error('Not team with team cloneOf: ' + teamId);

      return;
   }

   const teamName = propz.get(team, ['name']);
   const schoolId = propz.get(team, ['schoolId']);
   const school = event.invitedSchools.find(school => school.id === schoolId);

   if (typeof school === 'undefined'){
      console.error('Not school with team cloneOf: ' + teamId);
      return;
   }
   
   const schoolName = propz.get(school, ['name']);
   
   const isOneTeamFromSchoolCondition = isOneTeamFromSchool(teamId, teams, isSchoolIdInTeam);
   
   if (isOneTeamFromSchoolCondition) {
      name = `${schoolName}`;
   } else {
      name = `${schoolName}, ${teamName}`;
   }
   
   return name;
};

export function isOneTeamFromSchool(teamCloneOf: string, teams: any, isSchoolIdInTeam = false) {
   let isOneTeamFromSchool = true;
   const teamPrototype = teams.find((team: any) => team.id === teamCloneOf);

   if (typeof teamPrototype !== 'undefined') {
      const schoolId = teamPrototype.schoolId;
      const teamsFromOneSchool = teams.filter((team: any) => team.schoolId === schoolId);
      isOneTeamFromSchool = teamsFromOneSchool.length === 1;
   } else {
      if (isSchoolIdInTeam){
         isOneTeamFromSchool = false;
      } else {
      }
   }

   return isOneTeamFromSchool;
};

function getSchoolCricketWinGame(schoolId: string, event: SchoolEvent) {
   const cricketResult = propz.get(event, ['results', 'cricketResult', 'result']);
   const cricketWho = propz.get(event, ['results', 'cricketResult', 'who']);

   if (
      (cricketResult === CRICKET_RESULT.WON_BY_RUNS && cricketWho === schoolId) ||
      (cricketResult === CRICKET_RESULT.WON_BY_WICKETS && cricketWho === schoolId) ||
      (cricketResult === CRICKET_RESULT.WON_BY_INNINGS_AND_RUNS && cricketWho === schoolId) ||
      (cricketResult === CRICKET_RESULT.MATCH_AWARDED && cricketWho === schoolId)
   ) {
      return 1;
   } else {
      return 0;
   }
};

function getTeamCricketWinGame(teamCloneOfId: string, event: SchoolEvent, isSchoolIdInTeam = false) {
   const teamsData = propz.get(event, ['teamsData']);
   
   switch (true) {
      case Array.isArray(teamsData) && teamsData.length > 0:
         const team = isSchoolIdInTeam
            ? teamsData.find((team: any) => team.schoolId === teamCloneOfId)
            : teamsData.find((team: any) => team.cloneOf === teamCloneOfId);

         const teamId = propz.get(team, ['id']);
         
         const cricketResult = propz.get(event, ['results', 'cricketResult', 'result']);
         const cricketWho = propz.get(event, ['results', 'cricketResult', 'who']);

         if (
            (cricketResult === CRICKET_RESULT.WON_BY_RUNS && cricketWho === teamId) ||
            (cricketResult === CRICKET_RESULT.WON_BY_WICKETS && cricketWho === teamId) ||
            (cricketResult === CRICKET_RESULT.WON_BY_INNINGS_AND_RUNS && cricketWho === teamId) ||
            (cricketResult === CRICKET_RESULT.MATCH_AWARDED && cricketWho === teamId)
         ) {
            return 1;
         } else {
            return 0;
         }

      case Array.isArray(teamsData) && teamsData.length === 0:
         console.log('teamsData length === 0!');
         return 0;

      default:
         console.log('teamsData is not array!');
         return 0;
   }
};

function getTeamCricketDrawGame(event: SchoolEvent) {
   const cricketResult = propz.get(event, ['results', 'cricketResult', 'result']);

   if (cricketResult === CRICKET_RESULT.TIE || cricketResult === CRICKET_RESULT.DRAW) {
      return 1;
   } else {
      return 0;
   }
};

function getSchoolCricketLoseGame(schoolId: string, event: SchoolEvent) {
   const cricketResult = propz.get(event, ['results', 'cricketResult', 'result']);
   const cricketWho = propz.get(event, ['results', 'cricketResult', 'who']);
   
   if (
      (cricketResult === CRICKET_RESULT.WON_BY_RUNS && cricketWho !== schoolId) ||
      (cricketResult === CRICKET_RESULT.WON_BY_WICKETS && cricketWho !== schoolId) ||
      (cricketResult === CRICKET_RESULT.WON_BY_INNINGS_AND_RUNS && cricketWho !== schoolId) ||
      (cricketResult === CRICKET_RESULT.MATCH_AWARDED && cricketWho !== schoolId)
   ) {
      return 1;
   } else {
      return 0;
   }
};

function getTeamCricketLoseGame(teamCloneOfId: string, event: SchoolEvent, isSchoolIdInTeam = false) {
   const teamsData = propz.get(event, ['teamsData']);

   switch (true) {
      case Array.isArray(teamsData) && teamsData.length > 0:
         const team = isSchoolIdInTeam
            ? teamsData.find((team: any) => team.schoolId === teamCloneOfId)
            : teamsData.find((team: any) => team.cloneOf === teamCloneOfId);

         const teamId = propz.get(team, ['id']);

         const cricketResult = propz.get(event, ['results', 'cricketResult', 'result']);
         const cricketWho = propz.get(event, ['results', 'cricketResult', 'who']);

         if (
            (cricketResult === CRICKET_RESULT.WON_BY_RUNS && cricketWho !== teamId) ||
            (cricketResult === CRICKET_RESULT.WON_BY_WICKETS && cricketWho !== teamId) ||
            (cricketResult === CRICKET_RESULT.WON_BY_INNINGS_AND_RUNS && cricketWho !== teamId) ||
            (cricketResult === CRICKET_RESULT.MATCH_AWARDED && cricketWho !== teamId)
         ) {
            return 1;
         } else {
            return 0;
         }

      case Array.isArray(teamsData) && teamsData.length === 0:
         console.log('teamsData length === 0!');
         return 0;

      default:
         console.log('teamsData is not array!');
         return 0;
   }
};

export function getPointsForSingleGame(
   gameResult: string,
   textResult: string,
   tournamentScoring: number[]
 ): number {
   const winGameScore = tournamentScoring[0] || 0;
   const drawNonZeroScore = tournamentScoring[1] || 0;
   const drawZeroScore = tournamentScoring[2] || 0;
   const loseGameScore = tournamentScoring[3] || 0;
 
   const resultKey = gameResult.toLowerCase();
 
   switch (resultKey) {
     case 'win':
     case 'won':
       return winGameScore;
     case 'lose':
     case 'lost':
       return loseGameScore;
     case 'draw':
       return textResult.trim() === '0 : 0' ? drawZeroScore : drawNonZeroScore;
     default:
       return 0;
   }
 }
 
export function getNetballPointsForSingleGame(
   gameResult: string,
   teamScore: number,
   opponentScore: number,
   tournamentScoring: number[],
   halfScoreForNetball: boolean
): number {
   const goalDifference = Math.abs(teamScore - opponentScore);
   switch (gameResult.toLowerCase()) {
      case 'win':
      case 'won':
         return tournamentScoring[0] || 0;
      case 'draw':
         return tournamentScoring[1] || 0;
      case 'lose':
      case 'lost':
         if (tournamentScoring.length === 4) {
            if (halfScoreForNetball ? teamScore >= 0.5 * opponentScore : teamScore > 0.5 * opponentScore) {
               return tournamentScoring[2] || 0;
            } else {
               return tournamentScoring[3] || 0;
            }
         } else {
            if (goalDifference <= 4) {
               return tournamentScoring[2] || 0;
            } else if (teamScore >= 0.5 * opponentScore) {
               return tournamentScoring[3] || 0;
            } else {
               return tournamentScoring[4] || 0;
            }
         }
      default:
         return 0;
   }
}


function getPointsOfAllGames(
   winGame: number,
   drawGameWithGoals: number,
   drawGameWithoutGoals: number,
   loseGame: number,
   tournamentScoring: number[]
){
   const winGameScore = tournamentScoring[0] || 0;
   const drawGameWithGoalsScore = tournamentScoring[1] || 0;
   const drawGameWithoutScore = tournamentScoring[2] || 0;
   const loseGameScore = tournamentScoring[3] || 0;

   return winGame * winGameScore + drawGameWithGoals * drawGameWithGoalsScore + drawGameWithoutGoals * drawGameWithoutScore + loseGame * loseGameScore;
};

function getRF(id: string, event: SchoolEvent, isSchoolIdInTeam = false) {
   const teamsData = propz.get(event, ['teamsData'], []);

   const teamsScore = propz.get(event, ['results', 'teamScore'], []);
   const schoolsScore = propz.get(event, ['results', 'schoolScore'], []);

   let rf = 0;
   let team;
   let teamId: string;
   let teamScore;
   let schoolScore;
   
   switch (teamsData.length){
      case 2:
         team = teamsData.find((team: any) => team.cloneOf === id);
         teamId = propz.get(team, ['id']);
         teamScore = teamsScore.find((team: any) => team.teamId === teamId);
         
         if (typeof teamScore !== 'undefined') {
            rf = propz.get(teamScore, ['score'], 0);
         } else {
            console.error('teamScore not defined for team id: ' + id);
         }
         
         return Number(Math.floor(rf));

      case 1:
         team = isSchoolIdInTeam
            ? teamsData.find((team: any) => team.schoolId === id)
            : teamsData.find((team: any) => team.cloneOf === id);

         teamId = propz.get(team, ['id']);
         teamScore = teamsScore.find((team: any) => team.teamId === teamId);
         schoolScore = schoolsScore.find((school: any) => school.schoolId === id);

         if (typeof teamScore !== 'undefined') {
            rf = propz.get(teamScore, ['score'], 0);
         }

         if (typeof schoolScore !== 'undefined') {
            rf = propz.get(schoolScore, ['score'], 0);
         }

         if (typeof teamScore === 'undefined' && typeof schoolScore === 'undefined') {
            console.error('score not defined for id: ' + id);
         }

         return Number(Math.floor(rf));

      case 0:
         schoolScore = schoolsScore.find((school: any) => {
            return school.schoolId === id;
         });
         
         if (typeof schoolScore !== 'undefined') {
            rf = propz.get(schoolScore, ['score'], 0);
         } else {
            console.error('schoolScore not defined for school id: ' + id);
         }

         return Number(Math.floor(rf));
   }
};

function getRA(id: string, event: SchoolEvent, isSchoolIdInTeam = false) {
   const teamsData = propz.get(event, ['teamsData'], []);

   const teamsScore = propz.get(event, ['results', 'teamScore'], []);
   const schoolsScore = propz.get(event, ['results', 'schoolScore'], []);

   let ra = 0;
   let team;
   let teamId: string;
   let teamScore;
   let schoolScore;

   switch (teamsData.length){
      case 2:
         team = teamsData.find((team: any) => team.cloneOf === id);
         teamId = propz.get(team, ['id']);
         teamScore = teamsScore.find((team: any) => team.teamId !== teamId);

         if (typeof teamScore !== 'undefined') {
            ra = propz.get(teamScore, ['score'], 0);
         } else {
            console.error('teamScore not defined for team id: ' + id);
         }
         
         return Number(Math.floor(ra));

      case 1:
         team = isSchoolIdInTeam
            ? teamsData.find((team: any) => team.schoolId === id)
            : teamsData.find((team: any) => team.cloneOf === id);

         teamId = propz.get(team, ['id']);
         teamScore = teamsScore.find((team: any) => team.teamId !== teamId);
         schoolScore = schoolsScore.find((school: any) => school.schoolId !== id);

         if (typeof teamScore !== 'undefined') {
            ra = propz.get(teamScore, ['score'], 0);
         }

         if (typeof schoolScore !== 'undefined') {
            ra = propz.get(schoolScore, ['score'], 0);
         }

         if (typeof teamScore === 'undefined' && typeof schoolScore === 'undefined') {
            console.error('score not defined for id: ' + id);
         }

         return Number(Math.floor(ra));

      case 0:
         schoolScore = schoolsScore.find((school: any) => school.schoolId !== id);
         
         if (typeof schoolScore !== 'undefined') {
            ra = propz.get(schoolScore, ['score'], 0);
         } else {
            console.error('schoolScore not defined for school id: ' + id);
         }
         
         return Number(Math.floor(ra));
   }
};

function getSchoolGF(schoolId: string, event: SchoolEvent) {
   const teamsData = propz.get(event, ['teamsData'], []);

   const teamsScore = propz.get(event, ['results', 'teamScore'], []);
   const schoolsScore = propz.get(event, ['results', 'schoolScore'], []);

   switch (true) {
      case Array.isArray(teamsData) && teamsData.length > 0:
         const team = teamsData.find((team: any) => team.schoolId === schoolId);
         const teamId = propz.get(team, ['id']);

         if (Array.isArray(teamsScore)) {
            const teamScore = teamsScore.find((team: any) => team.teamId === teamId);

            let gf;

            if (typeof teamScore === 'undefined') {
               gf = propz.get(event, ['results', 'schoolScore', '0', 'score'], 0);
            } else {
               gf = propz.get(teamScore, ['score'], 0);
            }
            
            return Number(gf);
         } else {
            console.log('teamsScore is not array!');
            return 0;
         }

      case Array.isArray(teamsData) && teamsData.length === 0:
         if (Array.isArray(schoolsScore)) {
            const schoolScore = schoolsScore.find((school: any) => school.schoolId === schoolId);
            const gf = propz.get(schoolScore, ['score'], 0);
            return Number(gf);
         } else {
            console.log('schoolsScore is not array!');
            return 0;
         }

      default:
         console.log('teamsData is not array!');
         return 0;
   }
};

function getTeamGF(teamCloneOfId: string, event: SchoolEvent) {
   const teamsData = propz.get(event, ['teamsData'], []);
   
   const teamsScore = propz.get(event, ['results', 'teamScore'], []);
   const schoolsScore = propz.get(event, ['results', 'schoolScore'], []);
   
   switch (true) {
      case Array.isArray(teamsData) && teamsData.length > 0:
         const team = teamsData.find((team: any) => team.cloneOf === teamCloneOfId);
         const teamId = propz.get(team, ['id']);
         
         if (Array.isArray(teamsScore)) {
            const teamScore = teamsScore.find((team: any) => team.teamId === teamId);

            let gf;

            if (typeof teamScore === 'undefined') {
               // console.error('Team exist, but team results not find');
               gf = 0;
            } else {
               gf = propz.get(teamScore, ['score'], 0);
            }
            
            return Number(gf);
         } else {
            console.log('teamsScore is not array!');
            return 0;
         }

      case Array.isArray(teamsData) && teamsData.length === 0:
         console.log('teamsData length === 0!');
         return 0;

      default:
         console.log('teamsData is not array!');
         return 0;
   }
};

function getPlayGame(event: SchoolEvent) {
   const status = propz.get(event, ['status']);

   return status === 'FINISHED' ? 1 : 0;
};

function getCaptain(teamId: string, event: SchoolEvent): string | undefined {
   const team = event.teamsData.find(team => team.cloneOf === teamId);

   if (typeof team === 'undefined') {
      console.log(`Can not find team with team clone Id ${teamId}`);

      return;
   }

   const players = team.players;

   switch (true) {
      case players.length === 0: {
         console.log(`Team with id ${teamId} have 0 players`);
         return;
      }

      case players.length === 1: {
         const firstName	= propz.get(players, [0, 'firstName']);
         const lastName	= propz.get(players, [0, 'lastName']);

         return `${firstName} ${lastName}`;
      }

      case players.length > 1 && players.every(player => player.isCaptain !== true): {
         const firstName	= propz.get(players, [0, 'firstName']);
         const lastName	= propz.get(players, [0, 'lastName']);

         return `${firstName} ${lastName}`;
      }

      case players.findIndex(player => player.isCaptain === true) !== -1: {
         const captainIndex = players.findIndex(player => player.isCaptain === true);
         const firstName	= propz.get(players, [captainIndex, 'firstName']);
         const lastName 	= propz.get(players, [captainIndex, 'lastName']);

         return `${firstName} ${lastName}`;
      }

      default: {
         console.log(`Can not find captain for team ${teamId}`);

         return;
      }
   }
};

function getCrew(teamId: string, captain: string | undefined, event: any): string | undefined {
   const team = event.teamsData.find((team: any) => team.cloneOf === teamId);

   if (typeof team === 'undefined') {
      console.log(`Can not find team with team clone Id ${teamId}`);
      return;
   }

   const players = team.players || [];

   return players
      .filter((player: any) => `${player.firstName} ${player.lastName}` !== captain)
      .map((player: any) => `${player.firstName} ${player.lastName}`)
      .join(', ');
};

function getSailingPoints(teamId: string, event: any): number {
   const team = event.teamsData.find((team: any) => team.cloneOf === teamId);

   if (typeof team === 'undefined') {
      console.log(`Can not find team with team clone Id ${teamId}`);
      return 0;
   }

   const eventTeamId = team.id;
   const teamsScore = propz.get(event, ['results', 'teamScore']);
   const score = teamsScore.find((teamScore: any) => teamScore.teamId === eventTeamId);

   return propz.get(score, ['richScore', 'result'], 0);
}

function getSchoolWinGame(schoolId: string, event: SchoolEvent) {
   const [points, opponentPoints] = getResultAndOpponentResultBySchoolId(schoolId, event);

   return points > opponentPoints ? 1 : 0;
};

function getTeamWinGame(teamCloneOfId: string, event: SchoolEvent) {
   const [points, opponentPoints] = getResultAndOpponentResultByTeamId(teamCloneOfId, event);

   return points > opponentPoints ? 1 : 0;
};

function getSchoolDrawGame(schoolId: string, event: SchoolEvent) {
   if (event.status !=='FINISHED') {
      return 0;
   }

   const [points, opponentPoints] = getResultAndOpponentResultBySchoolId(schoolId, event);

   return points === opponentPoints ? 1 : 0;
};

function getTeamDrawGame(teamCloneOfId: string, event: SchoolEvent) {
   if (event.status !=='FINISHED') {
      return 0;
   }
   const [points, opponentPoints] = getResultAndOpponentResultByTeamId(teamCloneOfId, event);

   return points === opponentPoints ? 1 : 0;
};

function getSchoolLoseGame(schoolId: string, event: SchoolEvent) {
   const [points, opponentPoints] = getResultAndOpponentResultBySchoolId(schoolId, event);

   return points < opponentPoints ? 1 : 0;
};

function getTeamLoseGame(teamCloneOfId: string, event: SchoolEvent) {
   const [points, opponentPoints] = getResultAndOpponentResultByTeamId(teamCloneOfId, event);

   return points < opponentPoints ? 1 : 0;
};

function getSchoolDrawGameWithoutGoals(schoolId: string, event: SchoolEvent) {
   if (event.status !=='FINISHED') {
      return 0;
   }
   const [points, opponentPoints] = getResultAndOpponentResultBySchoolId(schoolId, event);

   return points === 0 && opponentPoints === 0 ? 1 : 0;
};

function getTeamDrawGameWithoutGoals(teamCloneOfId: string, event: SchoolEvent){
   if (event.status !=='FINISHED') {
      return 0;
   }
   const [points, opponentPoints] = getResultAndOpponentResultByTeamId(teamCloneOfId, event);

   return points === 0 && opponentPoints === 0 ? 1 : 0;
};

function getSchoolDrawGameWithGoals(schoolId: string, event: SchoolEvent) {
   if (event.status !=='FINISHED') {
      return 0;
   }
   const [points, opponentPoints] = getResultAndOpponentResultBySchoolId(schoolId, event);

   return points === opponentPoints && points > 0 ? 1 : 0;
};

function getTeamDrawGameWithGoals(teamCloneOfId: string, event: SchoolEvent) {
   if (event.status !=='FINISHED') {
      return 0;
   }

   const [points, opponentPoints] = getResultAndOpponentResultByTeamId(teamCloneOfId, event);

   return points === opponentPoints && points > 0 ? 1 : 0;
};

function getSchoolLoseGameLessHalf(schoolId: string, event: SchoolEvent) {
   const [points, opponentPoints] = getResultAndOpponentResultBySchoolId(schoolId, event);

   return points < opponentPoints && points >= Math.round(opponentPoints / 2) ? 1 : 0;
};

function getTeamLoseGameLessHalf(teamCloneOfId: string, event: SchoolEvent) {
   const [points, opponentPoints] = getResultAndOpponentResultByTeamId(teamCloneOfId, event);

   return points < opponentPoints && points >= Math.round(opponentPoints / 2) ? 1 : 0;
};

function getSchoolLoseGameMoreHalf(schoolId: string, event: SchoolEvent) {
   const [points, opponentPoints] = getResultAndOpponentResultBySchoolId(schoolId, event);

   return points < opponentPoints && points < Math.round(opponentPoints / 2) ? 1 : 0;
};

function getTeamLoseGameMoreHalf(teamCloneOfId: string, event: SchoolEvent) {
   const [points, opponentPoints] = getResultAndOpponentResultByTeamId(teamCloneOfId, event);

   return points < opponentPoints && points < Math.round(opponentPoints / 2) ? 1 : 0;
};

function getResultAndOpponentResultBySchoolId(schoolId: string, event: SchoolEvent) {
   const teamsData = propz.get(event, ['teamsData'], []);
   const schoolsScore = propz.get(event, ['results', 'schoolScore']);
   const teamsScore = propz.get(event, ['results', 'teamScore']);

   switch (teamsData.length){
      case 0: {
         if (Array.isArray(schoolsScore)) {
            const schoolScore = schoolsScore.find(school => school.schoolId === schoolId);

            const schoolOpponentScore = schoolsScore.find(school => school.schoolId !== schoolId);
            const schoolPoints = propz.get(schoolScore, ['richScore', 'result'], 0);
            const schoolOpponentPoints = propz.get(schoolOpponentScore, ['richScore', 'result'], 0);

            return [schoolPoints, schoolOpponentPoints];
         } else {
            return [0, 0];
         }
      }

      case 1: {
         const team = teamsData.find((team: any) => team.schoolId === schoolId);
         const teamId = propz.get(team, ['id']);

         if (Array.isArray(teamsScore)) {
            const teamScore = teamsScore.find(team => team.teamId === teamId);

            const schoolOpponentScore = schoolsScore.find((school: any) => school.schoolId !== schoolId);
            const teamPoints = propz.get(teamScore, ['richScore', 'result'], 0);
            const schoolOpponentPoints = propz.get(schoolOpponentScore, ['richScore', 'result'], 0);

            return [teamPoints, schoolOpponentPoints];
         } else {
            return [0, 0];
         }
      }

      case 2: {
         const team = teamsData.find((team: any) => team.schoolId === schoolId);
         const teamId = propz.get(team, ['id']);

         if (Array.isArray(teamsScore)) {
            const teamScore = teamsScore.find(team => team.teamId === teamId);

            const teamOpponentScore = teamsScore.find(team => team.teamId !== teamId);
            const teamPoints = propz.get(teamScore, ['richScore', 'result'], 0);
            const teamOpponentPoints = propz.get(teamOpponentScore, ['richScore', 'result'], 0);

            return [teamPoints, teamOpponentPoints];
         } else {
            return [0, 0];
         }
      }
      default:
         console.log('teamsData is not array!');
         return [0, 0];
   }
};

function getResultAndOpponentResultByTeamId(teamCloneOfId: string, event: SchoolEvent) {
   const teamsData = propz.get(event, ['teamsData'], []);
   const teamsScore = propz.get(event, ['results', 'teamScore']);
   const schoolsScore = propz.get(event, ['results', 'schoolScore']);

   if (teamsData.length > 0) {
      const team = teamsData.find((team: any) => team.cloneOf === teamCloneOfId);
      const teamId = propz.get(team, ['id']);

      const schoolId = propz.get(team, ['schoolId']);

      if (Array.isArray(teamsScore)) {
         const teamScore 	= teamsScore.find(team => team.teamId === teamId);
         const teamPoints 	= propz.get(teamScore, ['richScore', 'result'], 0);

         let opponentPoints = 0;

         const teamOpponentScore = teamsScore.find(team => team.teamId !== teamId);
         const schoolOpponentScore = schoolsScore.find((school: any) => school.schoolId !== schoolId);

         if (typeof teamOpponentScore !== 'undefined') {
            opponentPoints = propz.get(teamOpponentScore, ['richScore', 'result'], 0);
         } else {
            opponentPoints = propz.get(schoolOpponentScore, ['richScore', 'result'], 0);
         }

         return [teamPoints, opponentPoints];
      } else {
         return [0, 0];
      }
   } else {
      console.log('teamsData length === 0!');
      return [0, 0];
   }
};

function getSchoolGA(schoolId: string, event: SchoolEvent) {
   const teamsData = propz.get(event, ['teamsData'], []);

   const teamsScore = propz.get(event, ['results', 'teamScore'], []);
   const schoolsScore = propz.get(event, ['results', 'schoolScore'], []);

   switch (true) {
      case Array.isArray(teamsData) && teamsData.length > 0:
         const team = teamsData.find((team: any) => team.schoolId === schoolId);
         const teamId = propz.get(team, ['id']);
         
         if (Array.isArray(teamsScore)) {
            const teamScore = teamsScore.find((team: any) => team.teamId !== teamId);

            let ga;

            if (typeof teamScore === 'undefined') {
               ga = propz.get(event, ['results', 'schoolScore', '0', 'score'], 0);
            } else {
               ga = propz.get(teamScore, ['score'], 0);
            }

            return Number(ga);
         } else {
            console.log('teamsScore is not array!');
            return 0;
         }

      case Array.isArray(teamsData) && teamsData.length === 0:
         if (Array.isArray(schoolsScore)) {
            const schoolScore = schoolsScore.find((school: any) => school.schoolId !== schoolId);
            const ga = propz.get(schoolScore, ['score'], 0);

            return Number(ga);
         } else {
            console.log('schoolsScore is not array!');
            return 0;
         }

      default:
         console.log('teamsData is not array!');
         return 0;
   }
};

function getTeamGA(teamCloneOfId: string, event: SchoolEvent) {
   const teamsData = propz.get(event, ['teamsData'], []);

   const teamsScore = propz.get(event, ['results', 'teamScore'], []);
   const schoolsScore = propz.get(event, ['results', 'schoolScore'], []);

   switch (true) {
      case Array.isArray(teamsData) && teamsData.length > 0:
         const team = teamsData.find((team: any) => team.cloneOf === teamCloneOfId);
         const teamId = propz.get(team, ['id']);

         if (Array.isArray(teamsScore)) {
            const teamScore = teamsScore.find((team: any) => team.teamId !== teamId);

            let ga;

            if (typeof teamScore === 'undefined') {
               // console.error('Team exist, but team results not find');
               ga = 0;
            } else {
               ga = propz.get(teamScore, ['score'], 0);
            }

            return Number(ga);
         } else {
            console.log('teamsScore is not array!');
            return 0;
         }

      case Array.isArray(teamsData) && teamsData.length === 0:
         console.log('teamsData length === 0!');
         return 0;

      default:
         console.log('teamsData is not array!');
         return 0;
   }
};

function getGD(gf: number, ga: number) {
   return gf - ga
};

function uniqueArray(arr: any[]) {
   let obj: any = {};

   for (let i = 0; i < arr.length; i++) {
      let str = arr[i];

      obj[str] = arr[i];
   }

   return Object.keys(obj).map(key => obj[key]);
};

export function getArrayTeamsByEvent(event: any, teams: any) {
   let arrayTeams = [];

   const teamsData = propz.get(event, ['teamsData'], []);
   let teamName1, teamName2, schoolIdTeam1: any, schoolIdTeam2: any, schoolNameTeam1, schoolNameTeam2, school1, school2;
   let teamCloneOf1, teamCloneOf2, isOneTeamFromSchool1, isOneTeamFromSchool2;
   let teamName, schoolIdTeam: any, schoolNameTeam, school, teamCloneOf, isOneTeamFromSchoolCondition;

   switch (true) {
      case teamsData.length === 0:
         arrayTeams.push(propz.get(event, ['invitedSchools', '0', 'name']));
         arrayTeams.push(propz.get(event, ['invitedSchools', '1', 'name']));

         break;

      case teamsData.length === 1:
         teamName1 = propz.get(event, ['teamsData', '0', 'name']);
         schoolIdTeam1 = propz.get(event, ['teamsData', '0', 'schoolId']);

         school1 = event.invitedSchools.find((school: any) => school.id === schoolIdTeam1);
         school2 = event.invitedSchools.find((school: any) => school.id !== schoolIdTeam1);

         schoolNameTeam1 = school1.name;
         schoolNameTeam2 = school2.name;

         arrayTeams.push(`${schoolNameTeam1}, ${teamName1}`);
         arrayTeams.push(`${schoolNameTeam2}`);

         break;

      case teamsData.length === 2:
         teamName1 = propz.get(event, ['teamsData', '0', 'name']);
         schoolIdTeam1 = propz.get(event, ['teamsData', '0', 'schoolId']);
         teamName2 = propz.get(event, ['teamsData', '1', 'name']);
         schoolIdTeam2 = propz.get(event, ['teamsData', '1', 'schoolId']);
         
         teamCloneOf1 = propz.get(event, ['teamsData', '0', 'cloneOf']);
         teamCloneOf2 = propz.get(event, ['teamsData', '1', 'cloneOf']);
         
         isOneTeamFromSchool1 = isOneTeamFromSchool(teamCloneOf1, teams);
         isOneTeamFromSchool2 = isOneTeamFromSchool(teamCloneOf2, teams);
         
         school1 = event.invitedSchools.find((school: any) => school.id === schoolIdTeam1);
         school2 = event.invitedSchools.find((school: any) => school.id === schoolIdTeam2);
         
         schoolNameTeam1 = school1.name;
         schoolNameTeam2 = school2.name;
         
         if (isOneTeamFromSchool1) {
            arrayTeams.push(`${schoolNameTeam1}`);
         } else {
            arrayTeams.push(`${schoolNameTeam1}, ${teamName1}`);
         }
         
         if (isOneTeamFromSchool2) {
            arrayTeams.push(`${schoolNameTeam2}`);
         } else {
            arrayTeams.push(`${schoolNameTeam2}, ${teamName2}`);
         }
         
         break;

      case teamsData.length > 2:
         teamsData.forEach( teamData => {
            teamName = propz.get(teamData, ['name']);
            teamCloneOf = propz.get(teamData, ['cloneOf']);
            schoolIdTeam = propz.get(teamData, ['schoolId']);
            isOneTeamFromSchoolCondition = isOneTeamFromSchool(teamCloneOf, teams);
            school = event.invitedSchools.find((school: any) => school.id === schoolIdTeam);
            schoolNameTeam = school.name;
            
            if (isOneTeamFromSchoolCondition) {
               arrayTeams.push(`${schoolNameTeam}`);
            } else {
               arrayTeams.push(`${schoolNameTeam}, ${teamName}`);
            }
         });
   }

   return arrayTeams;
};

export function getRichResult(event: any, teams: any[]) {
   const teamsData = propz.get(event, ['teamsData'], []);

   const sportName = event.sport.name.toLowerCase();

   let teamName1,
      teamName2,
      schoolIdTeam1: any,
      schoolIdTeam2: any,
      schoolNameTeam1,
      schoolNameTeam2,
      gameResult1,
      gameResult2,
      school1,
      school2;
   let gf, ga;
   let cricketResult, cricketWho, teamId1: any, teamId2: any, result1, result2, points1, points2;
   let isOneTeamFromSchool1, isOneTeamFromSchool2, teamCloneOf1, teamCloneOf2;

   switch (true) {
      case teamsData.length === 0:
         schoolIdTeam1 = propz.get(event, ['invitedSchoolIds', '0']);
         schoolIdTeam2 = propz.get(event, ['invitedSchoolIds', '1']);
         schoolNameTeam1 = propz.get(event, ['invitedSchools', '0', 'name']);
         schoolNameTeam2 = propz.get(event, ['invitedSchools', '1', 'name']);
         
         if (isCricket(sportName)){
            cricketResult = propz.get(event, ['results', 'cricketResult', 'result']);
            cricketWho = propz.get(event, ['results', 'cricketResult', 'who']);
            
            switch(true){
               case cricketResult === CRICKET_RESULT.WON_BY_RUNS && cricketWho === schoolIdTeam1:
               case cricketResult === CRICKET_RESULT.WON_BY_WICKETS && cricketWho === schoolIdTeam1:
               case cricketResult === CRICKET_RESULT.WON_BY_INNINGS_AND_RUNS && cricketWho === schoolIdTeam1:
               case cricketResult === CRICKET_RESULT.MATCH_AWARDED && cricketWho === schoolIdTeam1:
                  gameResult1 = 'won';
                  gameResult2 = 'lost';

                  break;

               case cricketResult === CRICKET_RESULT.WON_BY_RUNS && cricketWho === schoolIdTeam2:
               case cricketResult === CRICKET_RESULT.WON_BY_WICKETS && cricketWho === schoolIdTeam2:
               case cricketResult === CRICKET_RESULT.WON_BY_INNINGS_AND_RUNS && cricketWho === schoolIdTeam2:
               case cricketResult === CRICKET_RESULT.MATCH_AWARDED && cricketWho === schoolIdTeam2:
                  gameResult2 = 'won';
                  gameResult1 = 'lost';

                  break;

               case cricketResult === CRICKET_RESULT.TIE:
               case cricketResult === CRICKET_RESULT.DRAW:
                  gameResult1 = 'draw';
                  gameResult2 = 'draw';

                  break;

               case cricketResult === CRICKET_RESULT.NO_RESULT:
                  gameResult1 = 'no result';
                  gameResult2 = 'no result';

                  break;

               case cricketResult === CRICKET_RESULT.TBD:
                  gameResult1 = 'tbd';
                  gameResult2 = 'tbd';

                  break;

               //debug info
               case cricketWho !== schoolIdTeam1 && cricketWho !== schoolIdTeam2 && typeof cricketWho !== 'undefined':
                  console.error('Can not find cricket who id!');

                  break;

               default:
                  //it can be true, if event not finished
                  console.error('Can not get rich result!');

                  break;
            }

            const schoolScore1 = event.results.schoolScore.find((score: any) => score.schoolId === schoolIdTeam1);
            const schoolScore2 = event.results.schoolScore.find((score: any) => score.schoolId === schoolIdTeam2);

            if (typeof schoolScore1 !== 'undefined') {
               points1 = propz.get(schoolScore1, ['richScore', 'result'], 0);
            } else {
               points1 = 0;
               //it can be true, if event not finished
               console.error('Can not find result for team id ' + schoolIdTeam1);
            }
            
            if (typeof schoolScore2 !== 'undefined') {
               points2 = propz.get(schoolScore2, ['richScore', 'result'], 0);
            } else {
               points2 = 0;
               //it can be true, if event not finished
               console.error('Can not find result for team id ' + schoolIdTeam2);
            }
            
            result1 = convertPointsCricket(points1).runs + '/' + convertPointsCricket(points1).wickets;
            result2 = convertPointsCricket(points2).runs + '/' + convertPointsCricket(points2).wickets;

            return [{
               teamName: typeof teamName1 !== 'undefined'
                  ? `${schoolNameTeam1}, ${teamName1}`
                  : schoolNameTeam1,
               gameResult: gameResult1,
               textResult: `${result1} : ${result2}`,
               schoolId: schoolIdTeam1
            }, {
               teamName: typeof teamName2 !== 'undefined'
                  ? `${schoolNameTeam2}, ${teamName2}`
                  : schoolNameTeam2,
               gameResult: gameResult2,
               textResult: `${result2} : ${result1}`,
               schoolId: schoolIdTeam2
            }];
            
            
         } else {
            gf = getSchoolGF(schoolIdTeam1, event);
            ga = getSchoolGA(schoolIdTeam1, event);
            
            switch (true) {
               case gf > ga:
                  gameResult1 = 'won';
                  gameResult2 = 'lost';
                  break;
               case gf < ga:
                  gameResult1 = 'lost';
                  gameResult2 = 'won';
                  break;
               case gf === ga:
                  gameResult1 = 'draw';
                  gameResult2 = 'draw';
                  break;
            }
            return [{
               teamName: typeof teamName1 !== 'undefined'
                  ? `${schoolNameTeam1}, ${teamName1}`
                  : schoolNameTeam1,
               gameResult: gameResult1,
               textResult: `${gf} : ${ga}`,
               schoolId: schoolIdTeam1
            }, {
               teamName: typeof teamName2 !== 'undefined'
                  ? `${schoolNameTeam2}, ${teamName2}`
                  : schoolNameTeam2,
               gameResult: gameResult2,
               textResult: `${ga} : ${gf}`,
               schoolId: schoolIdTeam2
            }];
         }
      case teamsData.length === 1:
         teamName1 = propz.get(event, ['teamsData', '0', 'name']);
         schoolIdTeam1 = propz.get(event, ['teamsData', '0', 'schoolId']);
         teamId1 = propz.get(event, ['teamsData', '0', 'id']);
         schoolIdTeam2 = event.invitedSchoolIds[0] === schoolIdTeam1
            ? propz.get(event, ['invitedSchoolIds', '1'])
            : propz.get(event, ['invitedSchoolIds', '0']);
         
         school1 = event.invitedSchools.find((school: any) => school.id === schoolIdTeam1);
         school2 = event.invitedSchools.find((school: any) => school.id === schoolIdTeam2);

         schoolNameTeam1 = school1.name;
         schoolNameTeam2 = school2.name;
         
         if (isCricket(sportName)){
            
            cricketResult = propz.get(event, ['results', 'cricketResult', 'result']);
            cricketWho = propz.get(event, ['results', 'cricketResult', 'who']);
            
            switch(true){
               case cricketResult === CRICKET_RESULT.WON_BY_RUNS && cricketWho === teamId1:
               case cricketResult === CRICKET_RESULT.WON_BY_WICKETS && cricketWho === teamId1:
               case cricketResult === CRICKET_RESULT.WON_BY_INNINGS_AND_RUNS && cricketWho === teamId1:
               case cricketResult === CRICKET_RESULT.MATCH_AWARDED && cricketWho === teamId1:
                  gameResult1 = 'won';
                  gameResult2 = 'lost';
                  break;
               case cricketResult === CRICKET_RESULT.WON_BY_RUNS && cricketWho === schoolIdTeam2:
               case cricketResult === CRICKET_RESULT.WON_BY_WICKETS && cricketWho === schoolIdTeam2:
               case cricketResult === CRICKET_RESULT.WON_BY_INNINGS_AND_RUNS && cricketWho === schoolIdTeam2:
               case cricketResult === CRICKET_RESULT.MATCH_AWARDED && cricketWho === schoolIdTeam2:
                  gameResult2 = 'won';
                  gameResult1 = 'lost';
                  break;
               case cricketResult === CRICKET_RESULT.TIE:
               case cricketResult === CRICKET_RESULT.DRAW:
                  gameResult1 = 'draw';
                  gameResult2 = 'draw';
                  break;
               case cricketResult === CRICKET_RESULT.NO_RESULT:
                  gameResult1 = 'no result';
                  gameResult2 = 'no result';
                  break;
               case cricketResult === CRICKET_RESULT.TBD:
                  gameResult1 = 'tbd';
                  gameResult2 = 'tbd';
                  break;
               //debug info
               case cricketWho !== teamId1 && cricketWho !== schoolIdTeam2 && typeof cricketWho !== 'undefined':
                  console.error('Can not find cricket who id!');
                  break;
               default:
                  //it can be true, if event not finished
                  console.error('Can not get rich result!');
                  break;
            }
            
            const teamScore1 = event.results.teamScore.find((score: any) => score.teamId === teamId1);
            const schoolScore2 = event.results.schoolScore.find((score: any) => score.schoolId === schoolIdTeam2);
            
            if (typeof teamScore1 !== 'undefined') {
               points1 = propz.get(teamScore1, ['richScore', 'result'], 0);
            } else {
               points1 = 0;
               //it can be true, if event not finished
               console.error('Can not find result for team id ' + schoolIdTeam1);
            }
            
            if (typeof schoolScore2 !== 'undefined') {
               points2 = propz.get(schoolScore2, ['richScore', 'result'], 0);
            } else {
               points2 = 0;
               //it can be true, if event not finished
               console.error('Can not find result for team id ' + schoolIdTeam2);
            }
            
            result1 = convertPointsCricket(points1).runs + '/' + convertPointsCricket(points1).wickets;
            result2 = convertPointsCricket(points2).runs + '/' + convertPointsCricket(points2).wickets;
            
            return [{
               teamName: typeof teamName1 !== 'undefined'
                  ? `${schoolNameTeam1}, ${teamName1}`
                  : schoolNameTeam1,
               gameResult: gameResult1,
               textResult: `${result1} : ${result2}`,
               schoolId: schoolIdTeam1
            }, {
               teamName: typeof teamName2 !== 'undefined'
                  ? `${schoolNameTeam2}, ${teamName2}`
                  : schoolNameTeam2,
               gameResult: gameResult2,
               textResult: `${result2} : ${result1}`,
               schoolId: schoolIdTeam2
            }];
            
            
         } else {
            gf = getSchoolGF(schoolIdTeam1, event);
            ga = getSchoolGA(schoolIdTeam1, event);
            
            switch (true) {
               case gf > ga:
                  gameResult1 = 'won';
                  gameResult2 = 'lost';
                  break;
               case gf < ga:
                  gameResult1 = 'lost';
                  gameResult2 = 'won';
                  break;
               case gf === ga:
                  gameResult1 = 'draw';
                  gameResult2 = 'draw';
                  break;
            }
            return [{
               teamName: typeof teamName1 !== 'undefined'
                  ? `${schoolNameTeam1}, ${teamName1}`
                  : schoolNameTeam1,
               gameResult: gameResult1,
               textResult: `${gf} : ${ga}`,
               schoolId: schoolIdTeam1
            }, {
               teamName: typeof teamName2 !== 'undefined'
                  ? `${schoolNameTeam2}, ${teamName2}`
                  : schoolNameTeam2,
               gameResult: gameResult2,
               textResult: `${ga} : ${gf}`,
               schoolId: schoolIdTeam2
            }];
         }
      case teamsData.length === 2:
         teamName1 = propz.get(event, ['teamsData', '0', 'name']);
         schoolIdTeam1 = propz.get(event, ['teamsData', '0', 'schoolId']);
         teamName2 = propz.get(event, ['teamsData', '1', 'name']);
         schoolIdTeam2 = propz.get(event, ['teamsData', '1', 'schoolId']);
         teamId1 = propz.get(event, ['teamsData', '0', 'id']);
         teamId2 = propz.get(event, ['teamsData', '1', 'id']);
         teamCloneOf1 = propz.get(event, ['teamsData', '0', 'cloneOf']);
         teamCloneOf2 = propz.get(event, ['teamsData', '1', 'cloneOf']);
         
         school1 = event.invitedSchools.find((school: any) => school.id === schoolIdTeam1);
         school2 = event.invitedSchools.find((school: any) => school.id === schoolIdTeam2);
         
         schoolNameTeam1 = school1.name;
         schoolNameTeam2 = school2.name;
         
         if (isCricket(sportName)){
            const teamScore1 = event.results.teamScore.find((score: any) => score.teamId === teamId1);
            const teamScore2 = event.results.teamScore.find((score: any) => score.teamId === teamId2);
            
            if (typeof teamScore1 !== 'undefined') {
               points1 = propz.get(teamScore1, ['richScore', 'result'], 0);
            } else {
               points1 = 0;
               //it can be true, if event not finished
               console.error('Can not find result for team id ' + teamId1);
            }
            
            if (typeof teamScore2 !== 'undefined') {
               points2 = propz.get(teamScore2, ['richScore', 'result'], 0);
            } else {
               points2 = 0;
               //it can be true, if event not finished
               console.error('Can not find result for team id ' + teamId2);
            }
            
            cricketResult = propz.get(event, ['results', 'cricketResult', 'result']);
            cricketWho = propz.get(event, ['results', 'cricketResult', 'who']);
            
            switch(true){
               case cricketResult === CRICKET_RESULT.WON_BY_RUNS && cricketWho === teamId1:
               case cricketResult === CRICKET_RESULT.WON_BY_WICKETS && cricketWho === teamId1:
               case cricketResult === CRICKET_RESULT.WON_BY_INNINGS_AND_RUNS && cricketWho === teamId1:
               case cricketResult === CRICKET_RESULT.MATCH_AWARDED && cricketWho === teamId1:
                  gameResult1 = 'won';
                  gameResult2 = 'lost';
                  break;
               case cricketResult === CRICKET_RESULT.WON_BY_RUNS && cricketWho === teamId2:
               case cricketResult === CRICKET_RESULT.WON_BY_WICKETS && cricketWho === teamId2:
               case cricketResult === CRICKET_RESULT.WON_BY_INNINGS_AND_RUNS && cricketWho === teamId2:
               case cricketResult === CRICKET_RESULT.MATCH_AWARDED && cricketWho === teamId2:
                  gameResult2 = 'won';
                  gameResult1 = 'lost';
                  break;
               case cricketResult === CRICKET_RESULT.TIE:
               case cricketResult === CRICKET_RESULT.DRAW:
                  gameResult1 = 'draw';
                  gameResult2 = 'draw';
                  break;
               case cricketResult === CRICKET_RESULT.NO_RESULT:
                  gameResult1 = 'no result';
                  gameResult2 = 'no result';
                  break;
               case cricketResult === CRICKET_RESULT.TBD:
                  gameResult1 = 'tbd';
                  gameResult2 = 'tbd';
                  break;
               //debug info
               case cricketWho !== teamId1 && cricketWho !== teamId2 && typeof cricketWho !== 'undefined':
                  console.error('Can not find cricket who id!');
                  break;
               default:
                  //it can be true, if event not finished
                  console.error('Can not get rich result!');
                  break;
            }
            
            result1 = convertPointsCricket(points1).runs + '/' + convertPointsCricket(points1).wickets;
            result2 = convertPointsCricket(points2).runs + '/' + convertPointsCricket(points2).wickets;
            
            isOneTeamFromSchool1 = isOneTeamFromSchool(teamCloneOf1, teams);
            isOneTeamFromSchool2 = isOneTeamFromSchool(teamCloneOf2, teams);
            
            return [{
               teamName: typeof teamName1 !== 'undefined' && !isOneTeamFromSchool1
                  ? `${schoolNameTeam1}, ${teamName1}`
                  : schoolNameTeam1,
               gameResult: gameResult1,
               textResult: `${result1} : ${result2}`,
               schoolId: schoolIdTeam1
            }, {
               teamName: typeof teamName2 !== 'undefined' && !isOneTeamFromSchool2
                  ? `${schoolNameTeam2}, ${teamName2}`
                  : schoolNameTeam2,
               gameResult: gameResult2,
               textResult: `${result2} : ${result1}`,
               schoolId: schoolIdTeam2
            }];
            
         } else {
            gf = getTeamGF(teamCloneOf1, event);
            ga = getTeamGA(teamCloneOf1, event);
            
            switch (true) {
               case gf > ga:
                  gameResult1 = 'won';
                  gameResult2 = 'lost';
                  break;
               case gf < ga:
                  gameResult1 = 'lost';
                  gameResult2 = 'won';
                  break;
               case gf === ga:
                  gameResult1 = 'draw';
                  gameResult2 = 'draw';
                  break;
            }
            
            isOneTeamFromSchool1 = isOneTeamFromSchool(teamCloneOf1, teams);
            isOneTeamFromSchool2 = isOneTeamFromSchool(teamCloneOf2, teams);
            
            return [{
               teamName: typeof teamName1 !== 'undefined' && !isOneTeamFromSchool1
                  ? `${schoolNameTeam1}, ${teamName1}`
                  : schoolNameTeam1,
               gameResult: gameResult1,
               textResult: `${gf} : ${ga}`,
               schoolId: schoolIdTeam1
            }, {
               teamName: typeof teamName2 !== 'undefined' && !isOneTeamFromSchool2
                  ? `${schoolNameTeam2}, ${teamName2}`
                  : schoolNameTeam2,
               gameResult: gameResult2,
               textResult: `${ga} : ${gf}`,
               schoolId: schoolIdTeam2
            }];
         }
   }
};

export function getRichResultForSailing(events: any[], tournamentTeams: any[], scoring: string) {
   const tournamentTeamIdList = tournamentTeams.map(team => team.id);
   const teamSchoolIdList = tournamentTeams.map(team => team.schoolId);

   type TeamPointInfo = {
      teamCloneOf: string
      nett: number
      totalPoints: number
      teamName: string
      groupTag: string
      teamCaptain: string
   }

   let richResultHashMap: any = {};
   let teamPointInfo: TeamPointInfo[] = [];

   tournamentTeamIdList.forEach( (tournamentTeamId, index) => {
      // searching for event which we can rely on to get captain, crew, teamName from
      // this is required because teams can take part only in some subset of all events.
      // we will just pick first which matches.
      const teamValuableEvent = events.find( event => {
         const captain = getCaptain(tournamentTeamId, event);
         const crew = getCrew(tournamentTeamId, captain, event);

         return typeof captain !== 'undefined' && typeof crew !== 'undefined';
      });

      let captain: string | undefined;
      let	crew: string | undefined;
      let	teamName: string | undefined;
      let groupTag: string | undefined; // right now team can only take part in one group tag. So, believing it best, and saving it.
      
      if (typeof teamValuableEvent !== 'undefined') {
         captain = getCaptain(tournamentTeamId, teamValuableEvent);
         crew = getCrew(tournamentTeamId, captain, teamValuableEvent);
         teamName = getTeamNameFromEvent(tournamentTeamId, teamValuableEvent, tournamentTeams, false);
         groupTag = teamValuableEvent.groupTag;
      }

      const eventInfoList = events
         .filter( event => {
            const isTeamRelatedToEvent = event.teamsData.findIndex((team: any) => team.cloneOf === tournamentTeamId) !== -1;
            return isTeamRelatedToEvent;
         })
         .map( (event, eventIndex) => {
            return {
               points: getSailingPoints(tournamentTeamId, event) as number | string,
               eventName: `${event.generatedNames.official}`,
               index: eventIndex,
               eventId: event.id,
               eventStatus: event.status,
               tournamentGroupId: event.tournamentGroupId
            }
         });

      const totalPoints: number = eventInfoList.reduce( (sum, eventInfo) => {
         return sum + (eventInfo.points as number)
      }, 0);
      
      const eventPoints: number[] = eventInfoList.map(eventInfo => (eventInfo.points as number));
      
      let nett: number;
      
      if (eventInfoList.length > 3) {
         const ignoredRacePoints = scoring === 'LESS_SCORES' ? Math.max(...eventPoints) : Math.min(...eventPoints) ;
         nett = totalPoints - ignoredRacePoints;
         
         //add brackets for ignored race
         const ignoredRaceIndex = eventPoints.findIndex(point => point === ignoredRacePoints);
         eventInfoList[ignoredRaceIndex].points = `(${ignoredRacePoints})`;
      } else {
         nett = totalPoints;
      }

      // if(!captain) {
         richResultHashMap[tournamentTeamId] = {
            captain: captain,
            crew: crew,
            teamName: teamName,
            schoolId: teamSchoolIdList[index],
            eventInfoList: eventInfoList,
            nett: nett,
            totalPoints: totalPoints
         };

         teamPointInfo.push({
            teamCloneOf: tournamentTeamId,
            nett: nett,
            totalPoints: totalPoints,
            teamName: teamName || '',
            groupTag: groupTag || '',
            teamCaptain: captain || ''
         });
      // }
   });

   const teamPointInfoArrayPerGroupTag: { [key: string]: TeamPointInfo[]} = {};

   teamPointInfo.forEach( teamPointInfo => {
      const arr = teamPointInfoArrayPerGroupTag[teamPointInfo.groupTag] || [];
      teamPointInfoArrayPerGroupTag[teamPointInfo.groupTag] = [...arr, teamPointInfo];
   });

   //sort by nett, if equal by total point, if equal then by name
   function teamPointInfoSorter(team1: TeamPointInfo, team2: TeamPointInfo) {
      switch (true) {
         case team1.nett > team2.nett:
            return scoring === 'LESS_SCORES' ? 1 : -1;

         case team1.nett < team2.nett:
            return scoring === 'LESS_SCORES' ? -1 : 1;

         case team1.nett === team2.nett:
            switch (true) {
               case team1.totalPoints > team2.totalPoints:
                  return scoring === 'LESS_SCORES' ? 1 : -1;

               case team1.totalPoints < team2.totalPoints:
                  return scoring === 'LESS_SCORES' ? -1 : 1;

               case team1.totalPoints === team2.totalPoints:
                  switch(true){
                     case team1.teamName > team2.teamName:
                        return scoring === 'LESS_SCORES' ? 1 : -1;

                     case team1.teamName < team2.teamName:
                        return scoring === 'LESS_SCORES' ? -1 : 1;
                  }
            }
      }

      return 0;
   }

   // sorting each array in teamPointInfoArrayPerGroupTag
   const groupTagArray = Object.keys(teamPointInfoArrayPerGroupTag);

   groupTagArray.forEach(groupTag => {
      if (groupTag !== 'undefined') { // yes string, because it is already casted to string.
         teamPointInfoArrayPerGroupTag[groupTag].sort(teamPointInfoSorter);

         teamPointInfoArrayPerGroupTag[groupTag].forEach((teamPoint, index) => {
            richResultHashMap[teamPoint.teamCloneOf].positionAfterRace = index + 1;
         });
      }
   });

   return richResultHashMap;
};

export function convertPointsCricket(countPoints: number): {runs: number, wickets: number}{
   const runs = Math.floor(countPoints);
   const wickets = Math.round(countPoints * 100) % 100;

   return {
      runs,
      wickets
   };
}
export function isShowIndividualScoreForTeam(event: SchoolEvent, player: any) {
   if (!player) return false;

   const { teamId } = player;

   const team = event.teamsData.find(teamData => teamData.id === teamId);
   const teamResults = event.results.teamScore.find(score => score.teamId === teamId);
   const isTeamResults = typeof teamResults !== 'undefined';
   const teamPlayers = propz.get(team, ['players'], []);

   const isPlayersResults = event.results.individualScore.some(score =>
      teamPlayers.some(teamPlayer => {
         const { userId, permissionId } = teamPlayer;
         const { userId: scoreUserId, permissionId: scorePermissionId, score: playerScore } = score;
         return scoreUserId === userId && scorePermissionId === permissionId && playerScore !== 0;
      })
   );

   return isTeamResults && isPlayersResults;
}
