import React, { useState, useEffect, useMemo } from 'react';
import Lazy from 'lazy.js';
import * as propz from 'propz';
import { styled, experimental_sx as sx } from '@mui/system';
import { FilterField } from 'models/filter';
import { FILTER_TYPE } from 'consts/table';
import { getFilters } from 'helpers/filters';
import { 
    getFootballTournamentStagesScoreData,
    getPointsForSingleGame,
    getNetballPointsForSingleGame
} from 'helpers/score';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Divider from '@mui/material/Divider';
import Filters from 'components/Filters';
import PageTitle from 'components/PageTitle';
import { checkAreFinishedEventsExist } from 'helpers/event';
import { SUBTYPE_OF_TOURNAMENT } from 'consts/tournament';

const FILTER_FIELDS: FilterField[] = [
   {
      text: 'Group',
      field: 'group',
      type: FILTER_TYPE.SELECT
   }
];

interface Props {
   events: any[];
   ids: { id: string, kind: string, isSchoolIdInTeam?: boolean }[];
   activeGroupId?: string;
   title?: string;
   subTypeTournament: string;
   teams: any[];
   scoring?: string;
   tournamentId?: string;
   tournamentScoring: number[];
   groups?: any[];
   tournament?: any; 
   halfScoreForNetball?: boolean;
}

const TableWrapper = styled(Box)(
   sx({
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: '6px',
      overflow: 'hidden'
   })
);

const TableHeadCell = styled(TableCell)(
   sx({
      fontWeight: 'bold'
   })
);

export default function StageMatrix(props: Props) {
   const { ids, events, teams, title, tournamentScoring, subTypeTournament, tournament, halfScoreForNetball } = props;

   const [results, setResults] = useState<any>([]);
   const [resultsFiltered, setResultsFiltered] = useState<any>([]);
   const [filters, setFilters] = useState<any>({});

   const groupNameFromFilter = useMemo(() => {
      if (!filters.group) return undefined;
      const match = filters.group.match(/^[A-Za-z0-9]+\s+(.*)$/);
      const result = match ? match[1] : filters.group;
      return result;
   }, [filters.group]);

   const customGroup = useMemo(() => {
      if (!tournament?.customGroups) return undefined;
      const found = tournament.customGroups.find((g: any) => g.name === groupNameFromFilter);
      return found;
   }, [groupNameFromFilter, tournament]);

   const stage = useMemo(() => {
      if (!tournament?.tournamentLayout || !customGroup) return undefined;
      const foundStage = tournament.tournamentLayout.find((s: any) => s.id === customGroup.stageId);
      console.log('Lookup stage with stageId:', customGroup?.stageId, 'found:', foundStage);
      return foundStage;
   }, [customGroup, tournament]);

   const showPointsColumn = stage?.type === 'GROUP';

   const getGroupOptions = () => {
      const groupValues = (Lazy(events) as any)
         .map((event: any) => event.groupWithAge)
         .uniq()
         .sort()
         .toArray();

      return groupValues
         .filter((group: any) => typeof group !== 'undefined')
         .map((group: any) => ({ text: group, value: group }));
   };

   const groupOptions = getGroupOptions() || [];
   const options = { group: groupOptions };

   useEffect(() => {
      const initialFilters = getFilters(FILTER_FIELDS, {
         group: groupOptions[0]?.value || ''
      });

      const allResults = getFootballTournamentStagesScoreData(
         ids,
         events,
         teams,
         tournamentScoring,
         subTypeTournament,
         tournament.halfScoreForNetball ?? false
      );

      const eventsFiltered = events.filter((event: any) => event.groupWithAge === initialFilters.group);
      const isFinishedEventsExist = checkAreFinishedEventsExist(eventsFiltered);
      const filteredResults = getFootballTournamentStagesScoreData(
         ids,
         eventsFiltered,
         teams,
         tournamentScoring,
         subTypeTournament,
         tournament.halfScoreForNetball ?? false
      );

      const sortedResults = isFinishedEventsExist
         ? [...(filteredResults || [])].sort(
              (a, b) => (b?.['Points'] ?? 0) - (a?.['Points'] ?? 0)
           )
         : [...(filteredResults || [])].sort(
              (a, b) => (a?.schoolName ?? '').localeCompare(b?.schoolName ?? '')
           );
   
      setResults(allResults);
      setResultsFiltered(sortedResults);
      setFilters(initialFilters);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const teamNames = useMemo(() => resultsFiltered.map((row: any) => row.schoolName), [resultsFiltered]);

   const filteredEvents = useMemo(() => {
      return events.filter((event: any) => event.groupWithAge === filters.group);
   }, [events, filters]);

   const getPointsStyle = (points: number): React.CSSProperties => {
      if (points === tournamentScoring[0]) return { fontWeight: 'bold', color: 'green' };
      if (points === tournamentScoring[1]) return { fontWeight: 'bold', color: 'blue' };
      if (points === tournamentScoring[2]) return { fontWeight: 'bold', color: 'orange' };
      if (points === tournamentScoring[3]) return { fontWeight: 'bold', color: 'red' };
      return { fontWeight: 'bold' };
   };

   const getMatrixResult = (rowTeam: string, colTeam: string): React.ReactNode => {
      if (rowTeam === colTeam) {
         return '-';
      }
  
      const matchingEvent = filteredEvents.find((event: any) => {
         if (event.status !== 'FINISHED') return false;
  
         let eventTeamNames: string[] = [];
         if (Array.isArray(event.teamsFullName)) {
            eventTeamNames = event.teamsFullName;
         } else if (typeof event.teamsFullName === 'string') {
            eventTeamNames = event.teamsFullName.split(',').map((name: string) => name.trim());
         }
         return eventTeamNames.includes(rowTeam) && eventTeamNames.includes(colTeam);
      });
  
      if (matchingEvent) {
         const result = matchingEvent.richResult;
         if (Array.isArray(result)) {
            const rowResult = result.find((r: any) => r.teamName === rowTeam);
            if (rowResult) {
               let points: number;
               if (subTypeTournament === SUBTYPE_OF_TOURNAMENT.NETBALL) {
                  const parts = rowResult.textResult.split(':').map((s: string) => s.trim());
                  const teamScore = Number(parts[0]);
                  const opponentScore = Number(parts[1]);
                  points = getNetballPointsForSingleGame(rowResult.gameResult, teamScore, opponentScore, tournamentScoring,   tournament.halfScoreForNetball ?? false);
               } else {
                  points = getPointsForSingleGame(rowResult.gameResult, rowResult.textResult, tournamentScoring);
               }
               return showPointsColumn ? (
                  <>
                     {rowResult.textResult} (<span style={getPointsStyle(points)}>{points}</span>)
                  </>
               ) : (
                  rowResult.textResult
               );
            }
            return '-';
         }
         if (result && typeof result === 'object') {
            let points: number;
            if (subTypeTournament === SUBTYPE_OF_TOURNAMENT.NETBALL) {
               const parts = result.textResult.split(':').map((s: string) => s.trim());
               const teamScore = Number(parts[0]);
               const opponentScore = Number(parts[1]);
               points = getNetballPointsForSingleGame(result.gameResult, teamScore, opponentScore, tournamentScoring,   tournament.halfScoreForNetball ?? false);
            } else {
               points = getPointsForSingleGame(result.gameResult, result.textResult, tournamentScoring);
            }
            return showPointsColumn ? (
               <>
                  {result.textResult} (<span style={getPointsStyle(points)}>{points}</span>)
               </>
            ) : (
               result.textResult
            );
         }
         return result || '-';
      }
      return '';
   };

   const renderTableHeader = () => {
      return (
         <TableHead>
            <TableRow>
               <TableHeadCell></TableHeadCell>
               {teamNames.map((teamName: string, idx: number) => (
                  <TableHeadCell key={`matrix_col_${idx}`}>{teamName}</TableHeadCell>
               ))}
               {showPointsColumn && <TableHeadCell>Total</TableHeadCell>}
            </TableRow>
         </TableHead>
      );
   };

   const renderTableBody = () => {
      return (
         <TableBody>
            {resultsFiltered.map((row: any, rowIndex: number) => {
               return (
                  <TableRow key={`matrix_row_${rowIndex}`}>
                     <TableCell sx={{ fontWeight: 'bold' }}>
                        {row.schoolName}
                     </TableCell>                    
                     {teamNames.map((colTeam: string, colIndex: number) => (
                        <TableCell
                           key={`matrix_cell_${rowIndex}_${colIndex}`}
                           sx={{
                              textAlign: 'center',
                              fontSize: '1.2rem',
                              backgroundColor: row.schoolName === colTeam ? 'grey.300' : undefined
                           }}
                        >
                           {getMatrixResult(row.schoolName, colTeam)}
                        </TableCell>
                     ))}
                     {showPointsColumn && (
                        <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: '1.2rem' }}>
                           {row.Points}
                        </TableCell>
                     )}
                  </TableRow>
               );
            })}
         </TableBody>
      );
   };

   const onFilterChange = (event: React.FormEvent<HTMLSelectElement>, filterField: string): void => {
      const filterValue = propz.get(event, ['target', 'value']);

      if (filterField === 'group') {
         const eventsFilteredNow = events.filter((event: any) => event.groupWithAge === filterValue);
         const isFinishedEventsExist = checkAreFinishedEventsExist(eventsFilteredNow);
         const filteredResults = getFootballTournamentStagesScoreData(
            ids,
            eventsFilteredNow,
            teams,
            tournamentScoring,
            subTypeTournament,
            halfScoreForNetball || false
         );

         const sortedFilteredResults = isFinishedEventsExist
            ? [...(filteredResults || [])].sort((a, b) => (b?.['Points'] ?? 0) - (a?.['Points'] ?? 0))
            : [...(filteredResults || [])].sort((a, b) => (a?.schoolName ?? '').localeCompare(b?.schoolName ?? ''));
         setResultsFiltered(sortedFilteredResults);
      }

      const nextFilters = {
         ...filters,
         [filterField]: filterValue
      };

      setFilters(nextFilters);
   };

   return (
      <Box>
         <PageTitle text={`Head-to-Head Matrix${stage ? ' - ' + stage.name : ''}`} />
         <Box>
            <Filters
               fields={FILTER_FIELDS}
               filters={filters}
               options={options}
               onFilterChange={onFilterChange}
            />
            <Divider sx={{ my: 3 }} />
         </Box>
         <TableWrapper>
            <TableContainer>
               <Table>
                  {renderTableHeader()}
                  {renderTableBody()}
               </Table>
            </TableContainer>
         </TableWrapper>
      </Box>
   );
}
