import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Lazy from 'lazy.js';
import * as propz from 'propz';
import { Promise } from 'bluebird';
import { styled, experimental_sx as sx } from '@mui/system';
import { School } from 'models/school';
import { SchoolEvent } from 'models/event';
import { Tournament } from 'models/tournament';
import { Record } from 'models/record';
import { ACTIVITIES_BLOCK_TYPE } from 'consts/common';
import { AGE_GROUPS, KIND_SERVER_TO_CLIENT_MAPPING } from 'consts/school';
import { SUBTYPE_OF_TOURNAMENT, TEAM_TOURNAMENT_DEFAULT_SCORING } from 'consts/tournament';
import { getTournamentTeams } from 'services/Tournaments/teams';
import { getAllTournamentOngoingEvents, getAllTournamentFinishedEvents } from 'services/Tournaments/events';
import { getRecords } from 'services/getRecords';
import { getArrayTeamsByEvent, getRichResult, getRichResultForSailing, isOneTeamFromSchool } from 'helpers/score';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stage from './Stage';
import StageMatrix from './StageMatrix';
import FinalsFixedTabs from './FinalsFixedTabs';
import PageTitle from 'components/PageTitle';
import PageWrapper from 'components/PageWrapper';
import HomeSlider from 'components/HomeSlider';
import ActivitiesBlock from 'components/ActivitiesBlock';
import Loader from 'components/loader/loader';
import SchoolSlider from '../../Tournament/Home/SchoolSlider';
import defaultLeaguePhoto from 'assets/default_league_photo.jpeg';

const ACTIVITIES_COUNT_TO_SHOW = 3;

interface Props {
   school: School;
   tournament: Tournament;
}

const SliderWrapper = styled(Box)(
   sx({
      p: {
         sm: 0,
         md: 3
      }
   })
);

export default function Home(props: Props) {
   const location = useLocation();
   const navigate = useNavigate();
   const schoolSliderRef = useRef<HTMLDivElement | null>(null);

   const { school, tournament } = props;
   const { id: schoolId } = school;
   const { id: tournamentId, photos } = tournament;

   const tournamentSubType = propz.get(tournament, ['subType'], '');
   const isSailing = tournamentSubType === SUBTYPE_OF_TOURNAMENT.SAILING;
   const scoring = propz.get(tournament, ['sport', 'scoring']);

   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [ongoingEvents, setOngoingEvents] = useState<SchoolEvent[]>([]);
   const [finishedEvents, setFinishedEvents] = useState<SchoolEvent[]>([]);
   const [tournamentTeams, setTournamentTeams] = useState<any[]>([]);
   const [records, setRecords] = useState<Record[]>([]);
   const [schools, setSchools] = useState([]);
   const [schoolsFiltered, setSchoolsFiltered] = useState([]);
   const [selectedSchool, setSelectedSchool] = useState<School>();

   const allEvents = useMemo(() => [...ongoingEvents, ...finishedEvents], [finishedEvents, ongoingEvents]);

   const eventsExtended = allEvents.map((event: any) => {
      const group = getTournamentGroupById(tournament.customGroups, event.tournamentGroupId);
      const competition = getEventCompetitionForTeamPublicSite(event.ages);
      const teamsFullName = getArrayTeamsByEvent(event, tournamentTeams);
      const groupWithAge = getGroupWithAge(event, group.name);

      let richResult;
      if (isSailing) {
         richResult = getRichResultForSailing(allEvents, tournamentTeams, scoring);
      } else {
         richResult = getRichResult(event, tournamentTeams);
      }

      return {
         ...event,
         tournamentGroup: group,
         competition: competition,
         teamsFullName: teamsFullName,
         richResult: richResult,
         groupWithAge: groupWithAge
      };
   });

   const isOngoingEventsExist = ongoingEvents.length > 0;
   const isFinishedEventsExist = finishedEvents.length > 0;

   const allPlayersSchoolIds = Lazy(allEvents)
      .map(item => item.individualsData)
      .flatten()
      .uniq('schoolId')
      .map(item => item.schoolId)
      .toArray();

   const allSchools = propz.get(tournament, ['schools'], []);
   const schoolsWithPlayers = allSchools.filter((school: any) => allPlayersSchoolIds.includes(school.id));

   const activitiesBlockOngoingEvents = ongoingEvents.slice(0, ACTIVITIES_COUNT_TO_SHOW);
   const activitiesBlockFinishedEvents = finishedEvents.slice(0, ACTIVITIES_COUNT_TO_SHOW);

   const disclaimer = propz.get(tournament, ['disclaimer']);
   const isDisclaimerExist = typeof disclaimer !== 'undefined';

   const integrations = propz.get(school, ['integrations'], []);
   const schoolTwitterProfileName = integrations[0];
   const isTwitterProfileExist = typeof schoolTwitterProfileName !== 'undefined' && schoolTwitterProfileName !== '';
   const isTwitterVisible = propz.get(school, ['publicSite', 'isTwitterVisible'], false);
   const isShowWidget = isTwitterVisible && isTwitterProfileExist;

   const isTournamentPhotosExist = photos.length > 0;
   const tournamentPhotosUrls: string[] = isTournamentPhotosExist
      ? photos.map((item) => propz.get(item, ['picUrl'], defaultLeaguePhoto))
      : [defaultLeaguePhoto];

   useEffect(() => {
      setIsLoading(true);

      const locationState = propz.get(location, ['state']) || undefined;
      const selectedSchoolFromLocation = propz.get(locationState, ['selectedSchool']);
      const isSelectedSchoolFromLocationExist = typeof selectedSchoolFromLocation !== 'undefined';

      const promises = [
         getAllTournamentOngoingEvents(schoolId, tournamentId),
         getAllTournamentFinishedEvents(schoolId, tournamentId),
         getTournamentTeams(schoolId, tournamentId),
         getRecords(schoolId),
      ];

      Promise.all(promises).then(([ongoingEvents, finishedEvents, tournamentTeams, records]) => {
         setOngoingEvents(ongoingEvents);
         setFinishedEvents(finishedEvents);
         setTournamentTeams(tournamentTeams);
         setRecords(records);
         setIsLoading(false);

         if (isSelectedSchoolFromLocationExist) {
            setSelectedSchool(selectedSchoolFromLocation);
   
            const mainContent: any = document.querySelector('#main-content');
            if (mainContent !== null) {
               mainContent.querySelector('.school-slider').scrollIntoView({ behavior: 'smooth' });
            }
         }
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      const schoolsData = (Lazy(allEvents) as any)
         .map((event: any) => {
            return event.invitedSchools.map((invitedSchool: any) => ({
               pic: invitedSchool.pic,
               name: invitedSchool.name,
               id: invitedSchool.id,
               kind: invitedSchool.kind,
               picUrl: invitedSchool.pic,
            }));
         })
         .flatten()
         .uniq('id')
         .toArray();

      setSchoolsFiltered(schoolsData);
      setSchools(schoolsData);

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [tournament, allEvents]);

   const onSearchSchool = (value: string) => {
      const schoolsFiltered = schools.filter(item => {
         const name = propz.get(item, ['name'], '');
         return name.toLowerCase().includes(value.toLowerCase());
      });
      setSchoolsFiltered(schoolsFiltered);
   };

   const onSelectSchoolClick = (school: School) => {
      setSelectedSchool(school);
   };

   const onCloseSelectedSchoolClick = () => {
      setSelectedSchool(undefined);
      navigate(location.pathname, {});
   };

   const onEventClick = (event: SchoolEvent) => {
      const { id: eventId } = event;
      const isSelectedSchoolExist = typeof selectedSchool !== 'undefined';

      isSelectedSchoolExist
         ? navigate(`/event/${eventId}`, { state: { selectedSchool } })
         : navigate(`/event/${eventId}`);
   };

   function getTournamentGroupById(groups: any[], groupId: string) {
      const foundGroup = groups.find(group => group.id === groupId);
      if (typeof foundGroup === 'undefined') {
         console.error(`Group with id ${groupId} not found`);
      } else {
         return foundGroup;
      }
   }

   function getGroupWithAge(event: SchoolEvent, groupName: string) {
      return `${getEventCompetitionForTeamPublicSite(event.ages)} ${groupName}`;
   }

   function getEventCompetitionForTeamPublicSite(ages: number[]) {
      let result = 'All ages';
      if (ages.length > 0) {
         result = `${AGE_GROUPS['ENGLISH'][ages[ages.length - 1]]}`;
      }
      return result;
   }

   const renderDisclaimer = () => {
      const disclaimerArray = disclaimer.split('\n');
      return (
         <Typography>
            {disclaimerArray.map((line: string, index: number) => (
               <React.Fragment key={index}>
                  {line}
                  <br />
               </React.Fragment>
            ))}
         </Typography>
      );
   };

   const groupByStage = (stageId: string): any[] => {
      return tournament.customGroups.filter(group => stageId === group.stageId);
   };

   const getSortedEventsForGroups = (groups: any[]) => {
      let eventsByGroup: any = [];
      groups.forEach(group => {
         eventsExtended.forEach(event => {
            if (event.tournamentGroupId === group.id) {
               eventsByGroup.push(event);
            }
         });
      });
      return [...eventsByGroup].sort((event1, event2) => {
         if (event1.groupWithAge > event2.groupWithAge) return 1;
         if (event1.groupWithAge < event2.groupWithAge) return -1;
         return 0;
      });
   };

   const getIds = (events = []) => {
      type ThatStrangeId = { id: string; kind: 'team' | 'school'; isSchoolIdInTeam?: boolean };
      let ids: ThatStrangeId[] = [];
      events.forEach(event => {
         const teamsData = propz.get(event, ['teamsData'], []),
            invitedSchoolIds = propz.get(event, ['invitedSchoolIds'], []);
         switch (teamsData.length) {
            // school vs school
            case 0: {
               invitedSchoolIds.forEach(schoolId => {
                  ids.push({
                     id: schoolId,
                     kind: 'school'
                  });
               });
               break;
            }
            // team vs school
            case 1: {
               const team = teamsData[0];
               const cloneOfId = propz.get(team, ['cloneOf']);
               const teamSchoolId = propz.get(team, ['schoolId'], '');
               if (typeof cloneOfId !== 'undefined') {
                  ids.push({
                     id: cloneOfId,
                     kind: 'team'
                  });
                  const schoolId = invitedSchoolIds.find(schoolId => schoolId !== teamSchoolId) || '';
                  ids.push({
                     id: schoolId,
                     kind: 'school'
                  });
               } else {
                  const teamSchoolId = propz.get(team, ['schoolId'], '');
                  ids.push({
                     id: teamSchoolId,
                     kind: 'team',
                     isSchoolIdInTeam: true
                  });
                  const schoolId = invitedSchoolIds.find(schoolId => schoolId !== teamSchoolId) || '';
                  ids.push({
                     id: schoolId,
                     kind: 'school'
                  });
               }
               break;
            }
            // team vs team
            case 2: {
               teamsData.forEach(team => {
                  const cloneOfId = propz.get(team, ['cloneOf']);
                  if (typeof cloneOfId !== 'undefined') {
                     ids.push({
                        id: cloneOfId,
                        kind: 'team'
                     });
                  } else {
                     console.error('Team with undefined cloneOf!');
                  }
               });
               break;
            }
            default: {
               teamsData.forEach(team => {
                  const cloneOfId = propz.get(team, ['cloneOf']);
                  if (typeof cloneOfId !== 'undefined') {
                     ids.push({
                        id: cloneOfId,
                        kind: 'team'
                     });
                  } else {
                     console.error('Team with undefined cloneOf!');
                  }
               });
            }
         }
      });
      return (Lazy(ids) as any).uniq('id').toArray() as ThatStrangeId[];
   };

   const finalsEvents = eventsExtended.filter(
      (event: any) =>
      event.tournamentGroup &&
      (
         event.tournamentGroup.name.toUpperCase().includes('FINAL') ||
         event.tournamentGroup.name.toUpperCase().includes('PLAYOFF')
      )
   );

   const renderStages = () => {
      const layout = tournament.tournamentLayout.sort((l1, l2) => l1.order - l2.order);
      return layout.map((stage: any) => {
         if (stage.type === 'FINAL') {
            return null; 
         } else {
            return (
               <React.Fragment key={stage.id}>
                  {renderGroupStage(stage, `${stage.id}-group`)}
                  {renderMatrixStage(stage, `${stage.id}-matrix`)}
               </React.Fragment>
            );
         }
      });
   };

   const renderGroupStage = (stage: any, keyProp: string): React.ReactNode => {
      const allEvents: any = [...ongoingEvents, ...finishedEvents];
      const tournamentScoring: number[] = Array.isArray(tournament.scoring)
         ? tournament.scoring.map((obj) => obj.score)
         : TEAM_TOURNAMENT_DEFAULT_SCORING;
      const groups = groupByStage(stage.id);
      const eventsByGroups = getSortedEventsForGroups(groups);
      const activeGroupId = propz.get(eventsByGroups, ['0', 'groupWithAge'], '');
      if (eventsByGroups.length === 0) {
         return null;
      }
      return (
         <Box key={keyProp} sx={{ mb: 5 }}>
            <Stage
               events={eventsByGroups}
               ids={getIds(allEvents)}
               title={stage.name}
               subTypeTournament={tournamentSubType}
               teams={tournamentTeams}
               tournamentScoring={tournamentScoring}
               tournamentId={tournament.id}
               groups={groups}
               activeGroupId={activeGroupId}
               tournament={tournament}
            />
         </Box>
      );
   };

   const renderMatrixStage = (stage: any, keyProp: string): React.ReactNode => {
      const allEvents: any = [...ongoingEvents, ...finishedEvents];
      const tournamentScoring: number[] = Array.isArray(tournament.scoring)
         ? tournament.scoring.map((obj) => obj.score)
         : TEAM_TOURNAMENT_DEFAULT_SCORING;
      const groups = groupByStage(stage.id);
      const eventsByGroups = getSortedEventsForGroups(groups);
      const activeGroupId = propz.get(eventsByGroups, ['0', 'groupWithAge'], '');
      if (eventsByGroups.length === 0) {
         return null;
      }
      return (
         <Box key={keyProp} sx={{ mb: 5 }}>
            <StageMatrix
               events={eventsByGroups}
               ids={getIds(allEvents)}
               title={stage.name}
               subTypeTournament={tournamentSubType}
               teams={tournamentTeams}
               tournamentScoring={tournamentScoring}
               tournamentId={tournament.id}
               groups={groups}
               activeGroupId={activeGroupId}
               tournament={tournament}
            />
         </Box>
      );
   };

   if (isLoading) {
      return <Loader />;
   }

   return (
      <>
         <SliderWrapper>
            <HomeSlider imageUrls={tournamentPhotosUrls} />
         </SliderWrapper>

         <PageWrapper>
            {renderStages()}

            {/* Render Finals section using FinalsFixedTabs */}
            {finalsEvents.length > 0 && (
               <FinalsFixedTabs 
                  finalsEvents={finalsEvents} 
                  tournamentFieldPictureUrl={tournament.sport.tournamentFieldPicUrl} 
               />
            )}

            <Box sx={{ mt: 4 }}>
               {isOngoingEventsExist && (
                  <ActivitiesBlock
                     title="Events schedule"
                     type={ACTIVITIES_BLOCK_TYPE.EVENTS}
                     items={activitiesBlockOngoingEvents}
                     showMoreLink="/schedule"
                     school={school}
                     onItemClick={(event) => onEventClick(event as SchoolEvent)}
                  />
               )}

               {isFinishedEventsExist && (
                  <ActivitiesBlock
                     title="Results"
                     type={ACTIVITIES_BLOCK_TYPE.RESULTS}
                     items={activitiesBlockFinishedEvents}
                     showMoreLink="/results"
                     school={school}
                     onItemClick={(event) => onEventClick(event as SchoolEvent)}
                  />
               )}
            </Box>

            <Box sx={{ mt: 4 }} ref={schoolSliderRef}>
               <SchoolSlider
                  schools={schoolsFiltered}
                  activeSchool={school}
                  events={eventsExtended}
                  teams={tournamentTeams}
                  tournament={tournament}
                  onSearch={onSearchSchool}
                  onSelectSchoolClick={onSelectSchoolClick}
                  onCloseClick={onCloseSelectedSchoolClick}
                  selectedSchool={selectedSchool}
               />
            </Box>

            {isDisclaimerExist && (
               <Box>
                  <Box sx={{ p: 2 }}>
                     {renderDisclaimer()}
                  </Box>
               </Box>
            )}
         </PageWrapper>
      </>
   );
}
